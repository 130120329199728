import React from "react";
import WhiteLabelLogo from "./WhiteLabelLogo";

function DeactivatedAccNotification() {
  return (
    <>
      <main className="grid h-[100vh] place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <WhiteLabelLogo />
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">
            Account is deactivated.
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Your account has been deactivated by an administrator.
          </p>
          <p className="mt-2 text-base leading-7 text-gray-600">
            Please reach out to your account administrator for further
            instructions.
          </p>
          <div className="mt-6 flex items-center justify-center gap-x-6">
            <a
              href="/auth/login"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Back to Login
            </a>
          </div>
        </div>
      </main>
    </>
  );
}

export default DeactivatedAccNotification;
