import React from "react";
import { useFormContext } from "react-hook-form";

export interface InputProps {
  name: string;
  placeholder?: string;
  label: string;
  disabled?: boolean;
  type: "password" | "text" | "email" | "textarea" | string;
  disabledClass?: string;
}

function LabelledInput({
  name,
  placeholder,
  label,
  type,
  disabled,
  disabledClass,
}: InputProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <div>
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
        <div className="mt-2">
          <input
            type={type}
            disabled={disabled}
            {...register(name)}
            className={`${disabledClass} block w-full rounded-md p-2 border-0 py-1.5 text-gray-900 outline-none shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-md focus:ring-2 focus:ring-inset focus:${errors[name] ? "ring-red-500" : "ring-primary-600"
              } sm:leading-6  ${errors[name] ? "ring-red-500" : ""} `}
            placeholder={placeholder}
          />
          {errors && errors[name] && (
            <span className="text-red-500 text-sm">
              {errors[name]?.message as string}
            </span>
          )}
        </div>
      </div>
    </>
  );
}

export default LabelledInput;