import React, { createContext, ReactNode, useState } from "react";

export interface ToasterContextType {
  show: boolean;
  message: string;
  severity: "success" | "error";
}

interface ModalPropsType {
  show: boolean;
  title: string;
  message: any;
  deleteFunction: any;
  deleteButtonText?: string;
}

interface InfoModalPropsType {
  show: boolean;
  title: string;
  message: string;
}

interface AlertMFAModalPropsType {
  show: boolean;
}

export interface ExportedContextValues {
  toasterProps: ToasterContextType;
  setToasterProps: React.Dispatch<React.SetStateAction<ToasterContextType>>;
  modalProps: ModalPropsType;
  infoModalProps: InfoModalPropsType;
  alertMFAModalProps: AlertMFAModalPropsType;
  setModalProps: React.Dispatch<React.SetStateAction<ModalPropsType>>;
  setAlertMFAModalProps: React.Dispatch<
    React.SetStateAction<AlertMFAModalPropsType>
  >;
  setInfoModalProps: React.Dispatch<React.SetStateAction<InfoModalPropsType>>;
}

export const GlobalToasterContext = createContext<ExportedContextValues | null>(
  null
);

function ToasterProvider({ children }: { children: ReactNode }) {
  const [toasterProps, setToasterProps] = useState<ToasterContextType>({
    show: false,
    message: "",
    severity: "success",
  });

  const [modalProps, setModalProps] = useState<ModalPropsType>({
    show: false,
    title: "",
    message: "",
    deleteFunction: "",
    deleteButtonText: "",
  });

  const [infoModalProps, setInfoModalProps] = useState({
    show: false,
    title: "",
    message: "",
  });

  const [alertMFAModalProps, setAlertMFAModalProps] = useState({
    show: false,
  });

  return (
    <GlobalToasterContext.Provider
      value={{
        toasterProps,
        setToasterProps,
        modalProps,
        infoModalProps,
        alertMFAModalProps,
        setAlertMFAModalProps,
        setModalProps,
        setInfoModalProps,
      }}
    >
      {children}
    </GlobalToasterContext.Provider>
  );
}

export default ToasterProvider;
