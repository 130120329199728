import React, {
  createContext,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router";
import { ResellerInterface } from "../sections/Reseller/Reseller";
import { get, post } from "../utils/httpMethods";

interface ResellerContextType {
  loading: boolean;
  resellers: ResellerInterface[];
  currentCompany: ResellerInterface;
  changeCompany: React.Dispatch<React.SetStateAction<ResellerInterface>>;
  reloadResellers: React.Dispatch<React.SetStateAction<string>>;
  fetchReseller: any;
}

export const ResellersContext = createContext<ResellerContextType | null>(null);

function ResellersProvider({ children }: { children: ReactNode }) {
  const { pathname } = useLocation();
  const [resellerList, setResellerList] = useState([] as any);

  const [selectedCompany, setSelectedCompany] = useState({} as any);
  const [loading, setLoading] = useState(false);

  const [isResellerPage, setIsResellerPage] = useState(false);

  const [selectedCompanyId, setSelectedCompanyId] = useState(pathname.split("/").includes("resellers") ? pathname.split("/")[3] : "")

  useEffect(() => {
    const isreseller = pathname.split("/").includes("resellers");
    setIsResellerPage(isreseller);
    setSelectedCompanyId(pathname.split("/").includes("resellers") ? pathname.split("/")[3] : "")
  }, [pathname]);

  ////////////////////////////////////////
  //   for Reseller dropdown local value
  ////////////////////////////////////////

  const fetchResellers = async (term = "") => {
    try {

      if (!isResellerPage) return;
      setLoading(true);
      const response = (await get(`/api/resellers?search=${term}`)) as any;
      setResellerList(response.data);
      setLoading(false);
      if (response.data?.length) {
        if (selectedCompanyId) {
          fetchReseller(selectedCompanyId);
        } else {
          setSelectedCompany(response.data[0]);
        }
        // setSelectedCompany(response.data[0]);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  const fetchReseller = async (id: string) => {
    try {
      if (!isResellerPage) return;
      const response = (await get(`/api/resellers/${id}`)) as any;
      if (Object.keys(response).length !== 0) setSelectedCompany(response);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchResellers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResellerPage]);

  return (
    <ResellersContext.Provider
      value={{
        loading: loading,
        resellers: resellerList,
        currentCompany: selectedCompany,
        changeCompany: (selectedCustomer: any) => {
          setSelectedCompany(selectedCustomer);
        },
        reloadResellers: (term) => {
          fetchResellers(term as any);
        },
        fetchReseller: (id: string) => {
          fetchReseller(id);
        },
      }}
    >
      {children}
    </ResellersContext.Provider>
  );
}

export default ResellersProvider;
