import React, { Fragment, useContext, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import useAuth from "../../hooks/useAuth";
import Avatar from "react-avatar";
import { XCircleIcon } from "@heroicons/react/24/outline";

import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import {
  ArrowUturnRightIcon,
  Bars3Icon,
  BellIcon,
  BuildingOffice2Icon,
  Cog6ToothIcon,
  UserGroupIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import SelectTenantModal from "../../sections/Tenants/SelectTenantModal";
import TenantDropdown from "../../sections/Tenants/TenantDropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LayoutContext } from "../../contexts/LayoutContext";
import { TenantsContext } from "../../contexts/TenantsContext";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
const employees = [
  "WanAware_Engineer",
  "WanAware_Report_User",
  "WanAware_Support",
  "WanAware_Finance",
  "Reseller_Engineer",
  "Reseller_Report_User",
  "Reseller_Support",
  "Reseller_Finance",
  "Customer_Engineer",
  "Customer_Report_User",
  "Customer_Support",
  "Customer_Finance",
];

const tenantAllowedRoles = [
  "WanAware_Super_User",
  "WanAware_Engineer",
  "WanAware_Report_User",
  "WanAware_Support",
  "WanAware_Finance",
  "Reseller_Admin",
  "Reseller_Engineer",
  "Reseller_Report_User",
  "Reseller_Support",
  "Reseller_Finance",
];
interface Props {
  sidebarOpen: {
    sidebarOpen: boolean;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
}
function Navbar() {
  const { logout, user, isFetchingUser, organization } = useAuth();
  const {
    selectedTenant,
    selectedTenantDetails,
    setSelectedTenantDetails,
    setSelectedTenant,
  } = useContext(TenantsContext)!;
  const { setSidebarOpen } = useContext(LayoutContext)!;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [openTenant, setOpenTenant] = useState(false);
  const [isAdmPage, setIsAdmPage] = useState(false);
  const [navItemList, setNavItemList] = useState<any>([]);
  const userTenant = JSON.parse(localStorage.getItem("currentUser") as any)
    ?.firebase?.tenant;
  const userNavigation = [
    {
      href: "/profile/general",
      label: "My Profile",
      icon: <UserIcon className="inline h-4" />,
    },
    {
      href: "/auth/login",
      label: "Logout",
      icon: <ArrowUturnRightIcon className="inline h-4" />,
    },
  ];

  useEffect(() => {
    const finalList = employees.includes(user?.role)
      ? userNavigation.filter((item) => item.label !== "Users" && item.label !== "Company")
      : user?.role === "WanAware_Super_User" || user?.role === "Customer_Admin"
        ? userNavigation.filter((item) => item.label !== "Company")
        : [...userNavigation];
    setNavItemList(finalList);
    setSelectedTenant(JSON.parse(localStorage.getItem("selectedTenant") as any) ||
      organization?.id)
  }, [user]);

  useEffect(() => {
    setIsAdmPage(pathname.split("/").includes("administration"));
  }, [pathname]);
  return (
    <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 justify-between">
      {/* <form className="relative flex flex-1" action="#" method="GET">
        <label htmlFor="search-field" className="sr-only">
          Search
        </label>
        <MagnifyingGlassIcon
          className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
          aria-hidden="true"
        />
        <input
          id="search-field"
          className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
          placeholder="Search..."
          type="search"
          name="search"
        />
      </form> */}
      <div className="flex">
        <button
          type="button"
          className="-m-2.5 mr-2 p-2.5 text-gray-700 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        {tenantAllowedRoles.includes(user?.role) ? (
          <>
            <TenantDropdown />
          </>
        ) : (
          <>
            <div className="self-center flex flex-1 gap-x-2">
              <BuildingOffice2Icon className="h-6 w-6 font-semibold" />
              <div className="font-medium">{organization?.name}</div>
            </div>
          </>
        )}
        {selectedTenantDetails?.name && selectedTenant !== organization?.id && (
          <div
            onClick={() => {
              setSelectedTenantDetails(organization);
              setSelectedTenant(organization?.id);
              if (isAdmPage) navigate("/dashboard");
            }}
            className="ml-2 flex items-center hover:underline cursor-pointer text-gray-400 hover:text-gray-800"
          >
            <XMarkIcon className="h-5 w-5 " aria-hidden="true" />
          </div>
        )}
      </div>

      <div className="flex items-center gap-x-4 lg:gap-x-6">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">View notifications</span>
          <BellIcon className="h-6 w-6" aria-hidden="true" />
        </button>
        {/* Separator */}
        <div
          className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
          aria-hidden="true"
        />
        {/* Profile dropdown */}
        <Menu as="div" className="relative">
          <Menu.Button className="-m-1.5 flex items-center p-1.5">
            <span className="sr-only">Open user menu</span>
            {!isFetchingUser ? (
              <Avatar
                src={user?.avatar}
                name={user?.first_name + " " + user?.last_name}
                size="30"
                round={true}
              />
            ) : (
              <></>
            )}
            <span className="hidden lg:flex lg:items-center">
              <span
                className="ml-4 text-sm font-semibold leading-6 text-gray-900 dark:text-gray-300"
                aria-hidden="true"
              >
                {user?.first_name} {user?.last_name}
              </span>
              <ChevronDownIcon
                className="ml-2 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2.5 w-44 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none dark:bg-gray-700 dark:text-gray-300">
              {navItemList.map((item: any) => (
                <Menu.Item key={item.label}>
                  {item.label === "Logout"
                    ? ({ active }) => (
                      <Link
                        to={item.href}
                        onClick={() => logout()}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700 capitalize dark:text-gray-300 dark:hover:bg-gray-500"
                        )}
                      >
                        {item.icon} {item.label}
                      </Link>
                    )
                    : ({ active }) => (
                      <Link
                        to={item.href}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700 capitalize dark:text-gray-300 dark:hover:bg-gray-500"
                        )}
                      >
                        {item.icon} {item.label}
                      </Link>
                    )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}

export default Navbar;
