import React, { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Controller } from "react-hook-form";
export interface InputProps {
  options: Array<{ name: string; value: string }>;
  label: string;
  name: string;
  error: any;
  defaultValue?: string;
  disabled?: boolean;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

function LabelledList({
  options,
  label,
  name,
  error,
  defaultValue,
  disabled,
}: InputProps) {
  const defaultOption = options.find((option) => option.name === defaultValue);
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  useEffect(() => {
    setSelectedOption(defaultOption);
  }, [defaultOption]);
  return (
    <div>
      <Controller
        name={name}
        render={({ field }) => (
          <Listbox
            value={selectedOption} // Set the value from the state variable
            onChange={(newValue) => {
              setSelectedOption(newValue); // Update the state when an option is selected
              field.onChange(newValue.name); // Update the form field value
            }}
            disabled={disabled}
          >
            {({ open }) => (
              <>
                <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                  {label}
                </Listbox.Label>
                <div className="relative mt-2">
                  <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-sky-400 sm:text-sm sm:leading-6">
                    <span className="block truncate">
                      {options.find(
                        (option) => option.name === selectedOption?.name
                      )?.value ?? `Select ${label}`}
                    </span>

                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-40 max-h-36  right-0 top-full w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {options.map((option, index) => (
                        <Listbox.Option
                          key={index}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "bg-primary-400 text-white"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-8 pr-4"
                            )
                          }
                          value={option}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate"
                                )}
                              >
                                {option.value}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-sky-400",
                                    "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        )}
      />
      <div className="text-sm text-red-500">{error && error.message}</div>
    </div>
  );
}

export default LabelledList;
