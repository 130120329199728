import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { get, post } from "../utils/httpMethods";
import { JWTContextType } from "../@types/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { setSession } from "../utils/jwt";
import { GlobalToasterContext } from "./ToasterContext";
export const AuthContext = createContext<JWTContextType | null>(null);

function JWTContext({ children }: any) {
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [user, setUser] = useState<any>(null);
  const [MFA, setMFADetails] = useState({} as any);
  const [organization, setOrganization] = useState(null);
  const [autoLogout, setAutoLogout] = useState<any>({});
  const prevUserData = useRef<any>(null);

  const { setToasterProps, setAlertMFAModalProps } =
    useContext(GlobalToasterContext)!;

  const { pathname } = useLocation();

  useEffect(() => {
    prevUserData.current = user;
    const countdown = parseInt(user?.mfa_disabled_at) + 86400000 - Date.now();

    setAutoLogout((prev: any) => ({ ...prev, counter: countdown }));

    let seconds = Math.floor(countdown / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;
    const style =
      "background-color: darkblue ; color: white; font-style: italic; border: 5px solid  hotpink ; font-size: 1.5em; padding:6px;";
    console.log(`%c${hours}h:${minutes}m:${seconds}s Before logout`, style);
  }, [user]);

  useEffect(() => {
    let to: any;

    if (!isNaN(autoLogout.counter)) {
      if (Math.sign(autoLogout.counter) !== -1) {
        console.log("timeout started");
        to = setTimeout(() => {
          logout();
        }, autoLogout.counter);
      }
    }

    return () => {
      clearTimeout(to);
    };
  }, [autoLogout.counter]);

  const fetchUser = async () => {
    try {
      setIsFetchingUser(true);
      const userData = (await get("/api/auth/self")) as any;
      const { user, organization } = userData;
      setUser(user);
      setAutoLogout((prev: any) => ({
        ...prev,
        is_mfa_enabled: user.is_mfa_enabled,
      }));
      localStorage.setItem("currentUser", JSON.stringify(user));
      setOrganization(organization);
      setIsFetchingUser(false);
      return userData;
    } catch (e) {
      setIsFetchingUser(false);
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("currentUser");
      localStorage.removeItem("selectedTenant");
      if (
        ![process.env.REACT_APP_ROOT_SUB_DOMAIN].includes(
          window.location.host.split(".")[0]
        ) &&
        window.location.host.split(".")[0] !== "localhost:3000"
      ) {
        !pathname.includes("auth") && navigate("/auth/login");
      } else {
        !pathname.includes("auth") && navigate("/auth/company/login");
      }
    }
  };

  useEffect(() => {
    console.log("context REFRESHED");
    const token = localStorage.getItem("accessToken");
    const user = JSON.parse(localStorage.getItem("currentUser") as any);

    if (window.location.host.split(".")[0] !== "localhost:3000") {
      setAlertMFAModalProps({
        show: user?.show_enable_mfa_advise && !user.is_mfa_enabled,
      });
    }

    const initialize = () => {
      if (!token) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("currentUser");
        localStorage.removeItem("selectedTenant");
        if (
          ![process.env.REACT_APP_ROOT_SUB_DOMAIN].includes(
            window.location.host.split(".")[0]
          ) &&
          window.location.host.split(".")[0] !== "localhost:3000"
        ) {
          !pathname.includes("auth") && navigate("/auth/login");
        } else {
          !pathname.includes("auth") && navigate("/auth/company/login");
        }
      } else {
        if (!pathname.includes("/auth/accept-invite")) {
          setSession(token);
          pathname.includes("auth") && navigate("/dashboard");
          fetchUser();
        }
      }
    };

    initialize();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async (email: string, password: string) => {
    setIsProcessing(true);
    try {
      const loginRes: any = await post("/api/auth/login", {
        email: email,
        password: password,
      });

      const {
        tokens: {
          access: { token },
        },
        user,
        organization,
      } = loginRes;
      setSession(token);
      setUser(user);

      localStorage.setItem("currentUser", JSON.stringify(user));
      localStorage.setItem("selectedTenant", JSON.stringify(organization?.id));
      setOrganization(organization);
      navigate(
        ["WanAware_Finance", "Reseller_Finance", "Customer_Finance"].includes(
          user.role
        )
          ? "/billing"
          : "/dashboard"
      );
      setIsProcessing(false);

      if (window.location.host.split(".")[0] !== "localhost:3000") {
        setAlertMFAModalProps({
          show: user?.show_enable_mfa_advise && !user.is_mfa_enabled,
        });
      }

      return loginRes;
    } catch (e: any) {
      setIsProcessing(false);
      setToasterProps({
        show: true,
        message: e.message,
        severity: "error",
      });
      return e;
    }
  };

  const loginWithDomain = async (
    email: string,
    password: string,
    recaptchaToken: string
  ) => {
    setIsProcessing(true);
    try {
      const loginRes: any = await post(
        "/api/auth/login",
        {
          email: email,
          password: password,
          recaptchaToken: recaptchaToken,
        },
        {
          headers: {
            subdomain:
              window.location.host.split(".")[0] !== "localhost:3000"
                ? window.location.host.split(".")[0]
                : "",
          },
        }
      );

      const { is_mfa_enabled, mfa_hash } = loginRes;

      // Navigate to verify OTP screen if MFA is enabled
      if (is_mfa_enabled) {
        setMFADetails({ password, mfa_hash, email });
        setIsProcessing(false);
        navigate("/auth/verify-otp");
        setAlertMFAModalProps({
          show: false,
        });
        return loginRes;
      }

      const {
        tokens: {
          access: { token },
          refresh,
        },
        user,
        organization,
      } = loginRes;
      setSession(token);
      setUser(user);
      localStorage.setItem("refreshToken", refresh.token);
      localStorage.setItem("currentUser", JSON.stringify(user));
      localStorage.setItem("selectedTenant", JSON.stringify(organization?.id));

      setOrganization(organization);
      navigate(
        ["WanAware_Finance", "Reseller_Finance", "Customer_Finance"].includes(
          user.role
        )
          ? "/billing"
          : ["WanAware_Super_User", "Reseller_Admin"].includes(user.role)
          ? "/super-user/customers"
          : "/dashboard",
        { replace: true }
      );

      if (window.location.host.split(".")[0] !== "localhost:3000") {
        setAlertMFAModalProps({
          show: user?.show_enable_mfa_advise && !user.is_mfa_enabled,
        });
      }

      setIsProcessing(false);
      return loginRes;
    } catch (e: any) {
      setIsProcessing(false);
      if (e.is_deactivated) {
        navigate("/auth/deactivated");
      } else {
        setToasterProps({
          show: true,
          message: e.message || e.error || e,
          severity: "error",
        });
      }
      return e;
    }
  };

  const verifyOTP = async (
    otp: string,
    recaptchaToken: string,
    useBackupCode: boolean
  ) => {
    setIsProcessing(true);
    try {
      const loginRes: any = await post("/api/auth/mfa", {
        otp,
        recaptchaToken: recaptchaToken,
        mfa_hash: MFA.mfa_hash,
        email: MFA.email,
        password: MFA.password,
        use_backup_code: useBackupCode,
      });

      const {
        tokens: {
          access: { token },
          refresh,
        },
        user,
        organization,
      } = loginRes;
      setSession(token);
      setUser(user);
      localStorage.setItem("refreshToken", refresh.token);
      localStorage.setItem("currentUser", JSON.stringify(user));
      localStorage.setItem("selectedTenant", JSON.stringify(organization?.id));
      setOrganization(organization);
      setMFADetails(null);
      navigate(
        ["WanAware_Finance", "Reseller_Finance", "Customer_Finance"].includes(
          user.role
        )
          ? "/billing"
          : ["WanAware_Super_User", "Reseller_Admin"].includes(user.role)
          ? "/super-user/customers"
          : "/dashboard",
        { replace: true }
      );
      setIsProcessing(false);
      return loginRes;
    } catch (e: any) {
      setIsProcessing(false);
      throw new Error(e.message);
    }
  };

  const forgotPassword = async (email: string) => {
    setIsProcessing(true);
    try {
      const forgotPasswordResult: any = await post(
        "/api/auth/forgot-password",
        {
          email: email,
        }
      );
      setIsProcessing(false);

      setToasterProps({
        show: true,
        message: forgotPasswordResult.message,
        severity: "success",
      });

      return forgotPasswordResult;
    } catch (e: any) {
      setIsProcessing(false);
      setToasterProps({
        show: true,
        message: e.message,
        severity: "error",
      });
      return e;
    }
  };

  const resetPassword = async (
    password: string,
    confirm_password: string,
    token: string
  ) => {
    setIsProcessing(true);
    try {
      const resetPasswordResult: any = await post(
        "/api/auth/password/self",
        {
          password,
          confirm_password,
        },
        {
          headers: { Authorization: token },
        }
      );
      setIsProcessing(false);

      setToasterProps({
        show: true,
        message: resetPasswordResult.message,
        severity: "success",
      });

      return resetPasswordResult;
    } catch (e: any) {
      setIsProcessing(false);
      setToasterProps({
        show: true,
        message: e.message,
        severity: "error",
      });
      return e;
    }
  };

  const acceptInviteAndRegister = async (
    first_name: string,
    last_name: string,
    email: string,
    password: string,
    subdomain: string | undefined,
    verificationToken: string,
    isFirstUser: any = "true",
    recaptchaToken: string
  ) => {
    setIsProcessing(true);
    try {
      const acceptInviteResult: any = await post(
        isFirstUser === "true"
          ? `/api/auth/users/self?token=${verificationToken}`
          : `/api/auth/accept-user-invite?token=${verificationToken}`,
        {
          first_name,
          last_name,
          email,
          password,
          subdomain,
          recaptchaToken,
        }
      );
      setIsProcessing(false);

      setToasterProps({
        show: true,
        message:
          acceptInviteResult.message ||
          "Your registration is successful, you can login to explore the WanAware",
        severity: "success",
      });

      navigate("/auth/login");
      return acceptInviteResult;
    } catch (e: any) {
      setIsProcessing(false);
      setToasterProps({
        show: true,
        message: e.message || e.response,
        severity: "error",
      });
      return e;
    }
  };

  const signup = async (
    first_name: string,
    last_name: string,
    email: string,
    company_name: string,
    subdomain: string,
    password: string,
    token: string
  ) => {
    setIsProcessing(true);
    try {
      const signupResult: any = await post(
        "/api/auth/signup",
        {
          first_name,
          last_name,
          email,
          company_name,
          subdomain,
          password,
          recaptchaToken: token,
        },
        {
          headers: {
            subdomain:
              window.location.host.split(".")[0] !== "localhost:3000" &&
              window.location.host.split(".")[0] !==
                process.env.REACT_APP_ROOT_SUB_DOMAIN
                ? window.location.host.split(".")[0]
                : "",
          },
        }
      );
      setIsProcessing(false);

      return signupResult;
    } catch (e: any) {
      setIsProcessing(false);
      setToasterProps({
        show: true,
        message: e.message || e.error,
        severity: "error",
      });
      return e;
    }
  };

  const logout = useCallback(async () => {
    // socket.leaveElementRoom(user?.id);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("selectedTenant");
    localStorage.removeItem(`elementFilters_${prevUserData.current?.id}`);
    localStorage.removeItem(
      `elements_current_page_${prevUserData.current?.id}`
    );
    localStorage.removeItem(
      `elements_current_limit_${prevUserData.current?.id}`
    );
    localStorage.removeItem("eventFilters");

    navigate("/auth/login");
  }, []);
  const getWhiteLabel = async () => {
    // Skip white label request if its on local or portal.stage
    if (
      window.location.host.split(".")[0] === "localhost:3000" ||
      window.location.host.split(".")[0] ===
        process.env.REACT_APP_ROOT_SUB_DOMAIN
    ) {
      return;
    }

    return await get(`/api/auth/white-label`, {
      headers: {
        subdomain: window.location.host.split(".")[0],
      },
    });
  };

  const refreshToken = async () => {};

  // trigger autologout after timer
  // useEffect(() => {
  //   if (!autoLogout?.is_mfa_enabled) {
  //     const to = setTimeout(() => {
  //       logout();
  //     }, autoLogout.counter);
  //     return () => {
  //       clearTimeout(to);
  //     };
  //   }
  // }, [autoLogout?.is_mfa_enabled, logout, autoLogout.counter]);
  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        loginWithDomain,
        isProcessing,
        user: user as any,
        organization: organization as any,
        fetchUser,
        isFetchingUser,
        forgotPassword,
        resetPassword,
        acceptInviteAndRegister,
        signup,
        getWhiteLabel,
        verifyOTP,
        MFA: MFA as any,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default JWTContext;
