import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import JWTContext from "./contexts/JWTContext";
import ToasterProvider from "./contexts/ToasterContext";
import CustomersProvider from "./contexts/CustomersContext";
import ResellersProvider from "./contexts/ResellerContext";
import TenantsProvider from "./contexts/TenantsContext";
import LayoutProvider from "./contexts/LayoutContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ToasterProvider>
      <BrowserRouter>
        <JWTContext>
          <TenantsProvider>
            <LayoutProvider>
              <ResellersProvider>
                <CustomersProvider>
                  <App />
                </CustomersProvider>
              </ResellersProvider>
            </LayoutProvider>
          </TenantsProvider>
        </JWTContext>
      </BrowserRouter>
    </ToasterProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics element. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
