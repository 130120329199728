import React from "react";

interface Props {
  variant: "success" | "error" | "primary" | "warning" | "secondary" | "high";
  text: string;
  theme: "light" | "dark";
}

function Badge({ variant, text, theme }: Props) {
  return theme === "dark" ? (
    variant === "success" ? (
      <span className="inline-flex items-center rounded-md bg-green-400/10 px-2 py-1 text-xs font-medium text-status-normal ring-1 ring-inset ring-green-400/20">
        {text}
      </span>
    ) : variant === "error" ? (
      <span className="inline-flex items-center rounded-md bg-red-400/10 px-2 py-1 text-xs font-medium text-status-critical ring-1 ring-inset ring-red-400/20">
        {text}
      </span>
    ) : variant === "warning" ? (
      <span className="inline-flex items-center rounded-md bg-amber-400/10 px-2 py-1 text-xs font-medium text-status-medium ring-1 ring-inset ring-amber-400/20">
        {text}
      </span>
    ) : variant === "primary" ? (
      <span className="inline-flex items-center rounded-md bg-primary-400/10 px-2 py-1 text-xs font-medium text-primary-400 ring-1 ring-inset ring-primary-400/20">
        {text}
      </span>
    ) : variant === "high" ? (
      <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-status-high ring-1 ring-inset ring-red-600/10">
        {text}
      </span>
    ) : (
      <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
        {text}
      </span>
    )
  ) : variant === "success" ? (
    <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-status-normal ring-1 ring-inset ring-green-600/10">
      {text}
    </span>
  ) : variant === "error" ? (
    <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-status-critical ring-1 ring-inset ring-red-600/10">
      {text}
    </span>
  ) : variant === "warning" ? (
    <span className="inline-flex items-center rounded-md bg-amber-100 px-2 py-1 text-xs font-medium text-status-medium ring-1 ring-inset ring-amber-600/10">
      {text}
    </span>
  ) : variant === "primary" ? (
    <span className="inline-flex items-center rounded-md bg-primary-100 px-2 py-1 text-xs font-medium text-primary-700 ring-1 ring-inset ring-primary-600/10">
      {text}
    </span>
  ) : variant === "high" ? (
    <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-status-high ring-1 ring-inset ring-red-600/10">
      {text}
    </span>
  ) : (
    <span className="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-600/10">
      {text}
    </span>
  );
}

export default Badge;
