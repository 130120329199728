import { Suspense, lazy, ElementType } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "../layouts/dashboard";
import LoadingScreen from "../components/LoadingScreen";

import MainCompTest from "../components/SocketIO/MainCompTest";
import ProtectedRoute from "./ProtectedRoutes";
import Events from "../sections/Events/Events";
import WanAwareAdmin from "../sections/WanAwareAdmin/WanAwareAdmin";
import Billing from "../sections/Settings/Billing";
import useAuth from "../hooks/useAuth";
import VerifyOTP from "../sections/auth/VerifyOTP";
import DeactivatedAccNotification from "../sections/auth/DeactivatedAccNotification";

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { user } = useAuth();

  //Include roles that have billing as default route
  const rolesForBillingAsDefaultRoute = [
    "WanAware_Super_User",
    "Reseller_Admin",
    "WanAware_Finance",
    "Reseller_Finance",
    "Customer_Finance",
  ];

  return useRoutes([
    {
      path: "auth",
      element: <AuthLayout />,
      children: [
        { element: <Navigate to="/auth/login" replace />, index: true },
        {
          path: "login",
          element: <LoginWithDomain />,
        },
        {
          path: "deactivated",
          element: <DeactivatedAccNotification />,
        },
        {
          path: "company/login",
          element: <CompanyLogin />,
        },
        {
          path: "sign-up",
          element: <Register />,
        },
        {
          path: "forgot-password",
          element: <ForgotPassword />,
        },
        {
          path: "reset-password",
          element: <ResetPassword />,
        },
        {
          path: "accept-invite",
          element: <AcceptInvitation />,
        },
        {
          path: "verify-otp",
          element: <VerifyOTP />,
        },
      ],
    },
    {
      path: "/profile",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/profile/general" replace />, index: true },
        { path: "general", element: <ProfileNavigator /> },
        { path: "security", element: <ProfileNavigator /> },
      ],
    },
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        {
          element: (
            <Navigate
              to={
                rolesForBillingAsDefaultRoute.includes(user?.role)
                  ? "/billing"
                  : "/dashboard"
              }
              replace
            />
          ),
          index: true,
        },
        {
          path: "dashboard",
          element: (
            <ProtectedRoute
              element={<Dashboard />}
              allowedRoles={[
                "Customer_Admin",
                "WanAware_Engineer",
                "Reseller_Engineer",
                "Customer_Engineer",
                "WanAware_Support",
                "Reseller_Support",
                "Customer_Support",
                "WanAware_Report_User",
                "Reseller_Report_User",
                "Customer_Report_User",
              ]}
            />
          ),
        },
        {
          path: "workers", //This will change
          children: [
            {
              path: "customer",
              element: (
                <ProtectedRoute
                  element={<WorkerNavigator />}
                  allowedRoles={[
                    "Customer_Admin",
                    "WanAware_Engineer",
                    "Reseller_Engineer",
                    "Customer_Engineer",
                    "WanAware_Support",
                    "Reseller_Support",
                    "Customer_Support",
                  ]}
                />
              ),
            },
            {
              path: "wanAware",
              element: (
                <ProtectedRoute
                  element={<WorkerNavigator />}
                  allowedRoles={[
                    "WanAware_Super_User",
                    "Reseller_Admin",
                    "Customer_Admin",
                    "WanAware_Engineer",
                    "Reseller_Engineer",
                    "Customer_Engineer",
                    "WanAware_Support",
                    "Reseller_Support",
                    "Customer_Support",
                  ]}
                />
              ),
            },
          ],
        },
        { path: "active-node", element: <ActiveNode /> },
        { path: "admin", element: <Admin /> },
        { path: "results", element: <Results /> },
        // { path: "orgstructure", element: <OrgStructure /> },
        { path: "reports", element: <Reports /> },
        { path: "targets-latency", element: <Targets /> },
        { path: "targets-availability", element: <Targets /> },
        { path: "workers", element: <Workers /> },
        {
          path: "elements",
          element: (
            <ProtectedRoute
              element={<Element />}
              allowedRoles={[
                "Customer_Admin",
                "WanAware_Engineer",
                "Reseller_Engineer",
                "Customer_Engineer",
                "WanAware_Support",
                "Reseller_Support",
                "Customer_Support",
              ]}
            />
          ),
        },
        {
          path: "billing",
          element: (
            <ProtectedRoute
              element={<Billing />}
              allowedRoles={[
                "WanAware_Super_User",
                "Reseller_Admin",
                "Customer_Admin",
                "WanAware_Finance",
                "Reseller_Finance",
                "Customer_Finance",
              ]}
            />
          ),
        },
        { path: "elements/bulk", element: <BulkElements /> },
        { path: "elements/export", element: <ExportElements /> },
        { path: "workers/export", element: <ExportElements /> },
        { path: "settings/users", element: <Users /> },
        { path: "settings/employees", element: <Users /> },
        { path: "settings/company", element: <Company /> },
        // { path: "elements/:id", element: <ElementDetails /> },
        { path: "test", element: <GoogleMap /> },
        {
          path: "reseller",
          element: <Reseller />,
        },
      ],
    },
    {
      path: "/elements/:id",
      element: <DashboardLayout />,
      children: [
        {
          element: <Navigate to="/elements/:id/insights" replace />,
          index: true,
        },
        { path: "insights", element: <ElementDetails /> },
        { path: "targets", element: <ElementDetails /> },
        { path: "details", element: <ElementDetails /> },
      ],
    },
    {
      path: "/super-user",
      element: <DashboardLayout />,
      children: [
        {
          path: "customers",
          element: (
            <ProtectedRoute
              element={<Customers />}
              allowedRoles={["WanAware_Super_User", "Reseller_Admin"]}
            />
          ),
          children: [
            {
              element: <Navigate to="customers/:id" replace />,
              index: true,
            },
            {
              path: ":id",
              element: (
                <ProtectedRoute
                  element={<Customers />}
                  allowedRoles={["WanAware_Super_User", "Reseller_Admin"]}
                />
              ),
            },
          ],
        },
        // { path: "customers/users", element: <Customers /> },
        { path: "test", element: <MainCompTest /> },
        {
          children: [
            {
              path: "resellers",
              element: (
                <ProtectedRoute
                  element={<Reseller />}
                  allowedRoles={["WanAware_Super_User"]}
                />
              ),
              children: [
                {
                  element: <Navigate to="resellers/:id" replace />,
                  index: true,
                },
                {
                  path: ":id",
                  element: (
                    <ProtectedRoute
                      element={<Reseller />}
                      allowedRoles={["WanAware_Super_User"]}
                    />
                  ),
                },
              ],
            },
            // { path: "resellers/customers", element: <Reseller /> },
            // { path: "resellers/users", element: <Reseller /> },
          ],
        },
        {
          path: "wanaware-admin",
          children: [
            {
              element: (
                <Navigate to="/super-user/wanaware-admin/employees" replace />
              ),
              index: true,
            },
            {
              path: "employees",
              element: (
                <ProtectedRoute
                  element={<WanAwareAdmin />}
                  allowedRoles={["WanAware_Super_User"]}
                />
              ),
            },
            {
              path: "element-management",
              element: (
                <ProtectedRoute
                  element={<WanAwareAdmin />}
                  allowedRoles={["WanAware_Super_User"]}
                />
              ),
            },
          ],
        },
      ],
    },
    {
      path: "/administration",
      element: <DashboardLayout />,
      children: [
        {
          element: <Navigate to="/general" replace />,
          index: true,
        },
        { path: "general", element: <Company /> },
        { path: "users", element: <Users /> },
        { path: "structures", element: <OrgStructure /> },
        {
          path: "workers",
          children: [{ path: "wanaware", element: <Workers /> }],
        },
      ],
    },
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/events" replace />, index: true },
        {
          path: "events",
          element: (
            <ProtectedRoute
              element={<EventNavigator />}
              allowedRoles={[
                "WanAware_Super_User",
                "Reseller_Admin",
                "Customer_Admin",
                "WanAware_Engineer",
                "Reseller_Engineer",
                "Customer_Engineer",
                "WanAware_Support",
                "Reseller_Support",
                "Customer_Support",
              ]}
            />
          ),
        },
        {
          path: "events/:id",
          element: (
            <ProtectedRoute
              element={<EventDetails />}
              allowedRoles={[
                "WanAware_Super_User",
                "Reseller_Admin",
                "Customer_Admin",
                "WanAware_Engineer",
                "Reseller_Engineer",
                "Customer_Engineer",
                "WanAware_Support",
                "Reseller_Support",
                "Customer_Support",
              ]}
            />
          ),
        },
      ],
    },
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/support" replace />, index: true },
        {
          path: "support",
          element: (
            <ProtectedRoute
              element={<TicketNavigator />}
              allowedRoles={[
                "WanAware_Super_User",
                "Reseller_Admin",
                "Customer_Admin",
                "WanAware_Engineer",
                "Reseller_Engineer",
                "Customer_Engineer",
                "WanAware_Support",
                "Reseller_Support",
                "Customer_Support",
              ]}
            />
          ),
        },
        {
          path: "support/:id",
          element: (
            <ProtectedRoute
              element={<TicketDetails />}
              allowedRoles={[
                "WanAware_Super_User",
                "Reseller_Admin",
                "Customer_Admin",
                "WanAware_Engineer",
                "Reseller_Engineer",
                "Customer_Engineer",
                "WanAware_Support",
                "Reseller_Support",
                "Customer_Support",
              ]}
            />
          ),
        },
      ],
    },
    {
      path: "*",
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// LAZY imports

////////////////////////
////      AUTH     ////
///////////////////////

const Login = Loadable(lazy(() => import("../sections/auth/Login")));
const LoginWithDomain = Loadable(
  lazy(() => import("../sections/auth/LoginWithDomain"))
);
const CompanyLogin = Loadable(
  lazy(() => import("../sections/auth/CompanyLogin"))
);

const Register = Loadable(lazy(() => import("../sections/auth/Register")));
const ForgotPassword = Loadable(
  lazy(() => import("../sections/auth/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("../sections/auth/ResetPassword"))
);
const AcceptInvitation = Loadable(
  lazy(() => import("../sections/auth/AcceptInvitation"))
);
const NotFound = Loadable(lazy(() => import("../sections/404/Page404")));
const AuthLayout = Loadable(lazy(() => import("../layouts/auth")));
const ActiveNode = Loadable(
  lazy(() => import("../sections/ActiveNode/ActiveWorker"))
);

const Element = Loadable(lazy(() => import("../sections/Elements/Element")));
const Reseller = Loadable(lazy(() => import("../sections/Reseller/Reseller")));
const Admin = Loadable(lazy(() => import("../sections/Admin/Admin")));
const ProfileNavigator = Loadable(
  lazy(() => import("../sections/Profile/ProfileNavigator"))
);
const WorkerNavigator = Loadable(
  lazy(() => import("../sections/Workers/WorkerNavigator"))
);
const SettingsNavigator = Loadable(
  lazy(() => import("../sections/Settings/SettingsNavigator"))
);
const Reports = Loadable(lazy(() => import("../sections/Reports/Reports")));
const Results = Loadable(lazy(() => import("../sections/Results/Results")));
const Targets = Loadable(lazy(() => import("../sections/Targets/Targets")));

const Dashboard = Loadable(
  lazy(() => import("../sections/Dashboard/Dashboard"))
);
const Workers = Loadable(lazy(() => import("../sections/Workers/Workers")));
const ElementDetails = Loadable(
  lazy(() => import("../sections/Elements/ElementDetails"))
);

const BulkElements = Loadable(
  lazy(() => import("../sections/Elements/BulkUploadWizard/Navigator"))
);

const ExportElements = Loadable(
  lazy(() => import("../sections/Elements/CsvExportWizard/Navigator"))
);

const Customers = Loadable(
  lazy(() => import("../sections/Customers/Customers"))
);
const GoogleMap = Loadable(
  lazy(() => import("../sections/Elements/GoogleMaps/GoogleMap"))
);
const TicketNavigator = Loadable(
  lazy(() => import("../sections/Support/TicketNavigator"))
);
const EventNavigator = Loadable(
  lazy(() => import("../sections/Events/EventNavigator"))
);
const EventDetails = Loadable(
  lazy(() => import("../sections/Events/EventDetails"))
);

const TicketDetails = Loadable(
  lazy(() => import("../sections/Support/TicketDetails"))
);

const Users = Loadable(lazy(() => import("../sections/Settings/Users/Users")));
const Company = Loadable(
  lazy(() => import("../sections/Settings/Company/Company"))
);

const OrgStructure = Loadable(
  lazy(() => import("../sections/Organization/OrgStructure"))
);
