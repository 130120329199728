import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthContext } from "./JWTContext";
import useAuth from "../hooks/useAuth";

export interface TenantContextType {
  selectedTenant: string;
  setSelectedTenant: React.Dispatch<React.SetStateAction<string>>;
  selectedTenantDetails: any;
  setSelectedTenantDetails: React.Dispatch<React.SetStateAction<object>>;
}

export const TenantsContext = createContext<TenantContextType | null>(null);

function TenantsProvider({ children }: { children: ReactNode }) {
  const { organization } = useAuth();
  const [selectedTenant, setSelectedTenant] = useState(
    JSON.parse(localStorage.getItem("selectedTenant") as any) ||
      organization?.id
  );
  const [selectedTenantDetails, setSelectedTenantDetails] = useState() as any;

  useEffect(() => {
    selectedTenant &&
      localStorage.setItem("selectedTenant", JSON.stringify(selectedTenant));
  }, [selectedTenant, organization]);

  return (
    <TenantsContext.Provider
      value={{
        selectedTenant,
        setSelectedTenant,
        selectedTenantDetails,
        setSelectedTenantDetails,
      }}
    >
      {children}
    </TenantsContext.Provider>
  );
}

export default TenantsProvider;
