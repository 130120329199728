import React from "react";
import "./App.css";
import Router from "./routes";
import useAuth from "./hooks/useAuth";
import LoadingScreen from "./components/LoadingScreen";
import { useLocation } from "react-router-dom";

function App() {
  const { user } = useAuth();
  const { pathname } = useLocation();

  return (
    <>{!user && !pathname.includes("auth") ? <LoadingScreen /> : <Router />}</>
  );
}

export default App;
