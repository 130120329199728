import React, { useState } from "react";
import GeneralForm from "./GeneralForm";
import OnPremiseSection from "./OnPremiseSection";
import CloudSection from "./CloudSection";
import IoTSection from "./IoTSection";

function ElementManagement() {
  const [refetch, setRefetch] = useState("");

  return (
    <div>
      <div className="flex flex-col space-y-12">
        <GeneralForm refetchProps={{ refetch, setRefetch }} />
        <OnPremiseSection refetchProps={{ refetch, setRefetch }} />
        <CloudSection refetchProps={{ refetch, setRefetch }} />
        <IoTSection refetchProps={{ refetch, setRefetch }} />
      </div>
    </div>
  );
}

export default ElementManagement;
