/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";
import {
  HomeIcon,
  RectangleGroupIcon,
  TicketIcon,
  UserGroupIcon,
  UsersIcon,
  WrenchIcon,
  ServerStackIcon,
  ComputerDesktopIcon,
  BuildingOffice2Icon as orgIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
  BuildingLibraryIcon,
  Cog8ToothIcon,
  CreditCardIcon,
} from "@heroicons/react/24/outline";
import { Dialog, Menu, Transition } from "@headlessui/react";

import {
  BuildingOffice2Icon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import { Link, NavLink, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { LayoutContext } from "../../contexts/LayoutContext";
import { TenantsContext } from "../../contexts/TenantsContext";
import logoDark from "../../assets/WanAware_Logo_Dark.png";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Sidebar() {
  const { sidebarOpen, setSidebarOpen } = useContext(LayoutContext)!;
  const { user, organization } = useAuth();
  const { selectedTenant } = useContext(TenantsContext)!;
  const location = useLocation();
  const [navList, setNavList] = useState<any>([]);

  let navigation: any = [
    {
      name: "Dashboard",
      href: "/dashboard",
      icon: HomeIcon,
      current: location.pathname.includes("dashboard"),
    },
    {
      name: "Services",
      icon: RectangleGroupIcon,
      children: [
        {
          name: "Elements",
          href: "/elements",
          current: location.pathname.includes("elements"),
          icon: ComputerDesktopIcon,
        },
        {
          name: "Workers",
          href: "/workers/customer",
          current: location.pathname.includes("workers"),
          icon: ServerStackIcon,
        },
      ],
    },
    {
      name: "Events",
      href: "/events",
      icon: TicketIcon,
      current: location.pathname.includes("events"),
    },
    {
      name: "Billing",
      href: "/billing",
      icon: CreditCardIcon,
      current: location.pathname.includes("billing"),
    },
    {
      name: "Administration",
      icon: WrenchIcon,
      children: [
        {
          name: "Customers",
          href: "/super-user/customers",
          icon: UsersIcon,
          current: location.pathname.includes("super-user/customer"),
        },
        {
          name: "Resellers",
          href: "/super-user/resellers",
          icon: UserGroupIcon,
          current: location.pathname.includes("super-user/reseller"),
        },
        {
          name: "WanAware",
          href: "/super-user/wanaware-admin",
          icon: BuildingOffice2Icon,
          current: location.pathname.includes("super-user/wanaware-admin"),
        },
        {
          name: "Reseller",
          href: "/settings/company",
          icon: BuildingOffice2Icon,
          current: location.pathname.includes("settings/company"),
        },
        {
          name: "Reseller Employees",
          href: "/settings/employees",
          icon: UserGroupIcon,
          current: location.pathname.includes("settings/employees"),
        },
        {
          name: "WanAware Workers",
          href: "/administration/workers/wanaware",
          current: location.pathname.includes("workers"),
          icon: ServerStackIcon,
        },
        {
          name: "General",
          href: "/administration/general",
          icon: Cog8ToothIcon,
          current: location.pathname.includes("administration/general"),
        },
        {
          name: "Users",
          href: "/administration/users",
          icon: BuildingLibraryIcon,
          current: location.pathname.includes("administration/users"),
        },
        {
          name: "Structures",
          href: "/administration/structures",
          current: location.pathname.includes("administration/structures"),
          icon: RectangleGroupIcon,
        },
      ],
    },
  ];

  const roles = [
    "WanAware_Super_User",
    "WanAware_Engineer",
    "WanAware_Report_User",
    "WanAware_Support",
    "WanAware_Finance",
    "Reseller_Admin",
    "Reseller_Engineer",
    "Reseller_Report_User",
    "Reseller_Support",
    "Reseller_Finance",
    "Customer_Admin",
    "Customer_Engineer",
    "Customer_Report_User",
    "Customer_Support",
    "Customer_Finance",
  ];

  const engineerRoles = [
    "WanAware_Engineer",
    "Reseller_Engineer",
    "Customer_Engineer",
  ];
  const reportUserRoles = [
    "WanAware_Report_User",
    "Reseller_Report_User",
    "Customer_Report_User",
  ];
  const financeRoles = [
    "WanAware_Finance",
    "Reseller_Finance",
    "Customer_Finance",
  ];
  const supportRoles = [
    "WanAware_Support",
    "Reseller_Support",
    "Customer_Support",
  ];
  const administrationNotAllowedRoles = [
    "Customer_Engineer",
    "Customer_Report_User",
    "Customer_Finance",
    "Customer_Support",
  ];

  let res;
  useEffect(() => {
    const finalList =
      user?.role === "WanAware_Super_User"
        ? selectedTenant === organization.id
          ? (navigation = navigation
              .map((nav: any) => {
                return nav.name === "Administration"
                  ? {
                      ...nav,
                      children: nav.children?.filter(
                        (child: any) =>
                          (!child.href.includes("administration") ||
                            child.name === "WanAware Workers") &&
                          child.name !== "Reseller" &&
                          child.name !== "Reseller Employees"
                      ),
                    }
                  : nav;
              })
              .filter((nav: any) => {
                return !["Dashboard", "Services"].includes(nav.name);
              }))
          : (navigation = navigation
              .map((nav: any) => {
                return nav.name === "Administration"
                  ? {
                      ...nav,
                      children: nav.children?.filter(
                        (child: any) =>
                          child.name !== "Reseller" &&
                          child.name !== "Reseller Employees"
                      ),
                    }
                  : nav;
              })
              .filter((nav: any) => {
                return !["Dashboard", "Services"].includes(nav.name);
              }))
        : user?.role === "Reseller_Admin"
        ? (navigation = navigation
            .map((nav: any) => {
              return nav.name === "Administration"
                ? selectedTenant === organization.id
                  ? {
                      ...nav,
                      children: nav.children?.filter(
                        (child: any) =>
                          child.name === "Customers" ||
                          child.name === "Reseller" ||
                          child.name === "Reseller Employees"
                      ),
                    }
                  : {
                      ...nav,
                      children: nav.children?.filter(
                        (child: any) =>
                          child.name !== "Resellers" &&
                          child.name !== "WanAware"
                      ),
                    }
                : nav;
            })
            .filter((nav: any) => {
              return !["Dashboard", "Services"].includes(nav.name);
            }))
        : user?.role === "Customer_Admin"
        ? (navigation = navigation.map((nav: any) => {
            return nav.name === "Administration"
              ? {
                  ...nav,
                  children: nav.children?.filter(
                    (child: any) =>
                      !child.href.includes("super-user") &&
                      child.name !== "Reseller" &&
                      child.name !== "Reseller Employees" &&
                      child.name !== "WanAware Workers"
                  ),
                }
              : nav;
          }))
        : engineerRoles.includes(user?.role)
        ? (navigation = navigation
            .map((nav: any) => {
              return nav.name === "Administration"
                ? {
                    ...nav,
                    children: nav.children?.filter(
                      (child: any) =>
                        user?.role === "WanAware_Engineer" &&
                        child.name === "WanAware Workers"
                    ),
                  }
                : nav;
            })
            .filter((nav: any) => {
              return !(
                nav.name === "Administration" && nav.children?.length === 0
              );
            })
            .filter((nav: any) => {
              return nav.name !== "Billing";
            }))
        : supportRoles.includes(user?.role)
        ? (navigation = navigation =
            navigation
              .map((nav: any) => {
                return nav.name === "Administration"
                  ? {
                      ...nav,
                      children: nav.children?.filter(
                        (child: any) =>
                          user?.role === "WanAware_Support" &&
                          child.name === "WanAware Workers"
                      ),
                    }
                  : nav;
              })
              .filter((nav: any) => {
                return !(
                  nav.name === "Administration" && nav.children?.length === 0
                );
              })
              .filter((nav: any) => {
                return nav.name !== "Billing";
              }))
        : reportUserRoles.includes(user?.role)
        ? (navigation = navigation =
            navigation.filter((nav: any) => {
              return (
                nav.name !== "Services" &&
                nav.name !== "Billing" &&
                nav.name !== "Administration"
              );
            }))
        : financeRoles.includes(user?.role)
        ? (navigation = navigation.filter((nav: any) => {
            return (
              nav.name !== "Administration" &&
              nav.name !== "Services" &&
              nav.name !== "Reports" &&
              nav.name !== "Dashboard" &&
              nav.name !== "Events"
            );
          }))
        : navigation.filter((nav: any) => {
            return nav.name !== "Administration";
          });
    setNavList(finalList);
  }, [user, selectedTenant]);

  // testing side bar

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4 ring-1 ring-white/10">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                      className="h-9 w-auto"
                      src={
                        organization?.light_logo
                          ? organization.light_logo
                          : logoDark
                      }
                      alt="Your Company"
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="-mx-2 space-y-1">
                      {navList.map((item: any) => (
                        <li key={item.name}>
                          {!item.children ? (
                            <NavLink to={item.href}>
                              {({ isActive }) => (
                                <span
                                  className={classNames(
                                    isActive
                                      ? "bg-[#EEF2FF] text-gray-700"
                                      : "text-gray-500 hover:text-gray-700 hover:bg-[#EEF2FF]",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </span>
                              )}
                            </NavLink>
                          ) : (
                            <Disclosure as="div">
                              {({ open }) => (
                                <>
                                  <Disclosure.Button
                                    className={classNames(
                                      item.current
                                        ? "bg-[#EEF2FF] text-gray-700"
                                        : "text-gray-500 hover:text-gray-700 hover:bg-[#EEF2FF]",
                                      "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold"
                                    )}
                                  >
                                    <item.icon
                                      className="h-6 w-6 shrink-0"
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                    <ChevronRightIcon
                                      className={classNames(
                                        open
                                          ? "rotate-90 text-gray-700"
                                          : "text-gray-500",
                                        "ml-auto h-5 w-5 shrink-0"
                                      )}
                                      aria-hidden="true"
                                    />
                                  </Disclosure.Button>
                                  <Disclosure.Panel
                                    as="ul"
                                    className="mt-1 px-2"
                                  >
                                    {item.children.map((subItem: any) => (
                                      <li key={subItem.name}>
                                        {/* 44px */}
                                        <NavLink to={subItem.href}>
                                          {({ isActive }) => (
                                            <span
                                              className={classNames(
                                                isActive
                                                  ? "bg-[#EEF2FF] text-gray-700"
                                                  : "text-gray-500 hover:text-gray-700 hover:bg-[#EEF2FF]",
                                                "flex gap-x-3 rounded-md my-1 pr-2 pl-8 py-2 text-sm leading-6"
                                              )}
                                            >
                                              {subItem.icon && (
                                                <subItem.icon
                                                  className="h-6 w-6 shrink-0"
                                                  aria-hidden="true"
                                                />
                                              )}
                                              {subItem.name}
                                            </span>
                                          )}
                                        </NavLink>
                                      </li>
                                    ))}
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          )}
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-60 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 px-6 bg-white pb-4">
          <div className="flex h-16 shrink-0 items-center">
            <img
              className="h-8 w-auto"
              src={organization?.light_logo ? organization.light_logo : logoDark}
              alt="Your Company"
            />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-2">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navList.map((item: any) => (
                    <li key={item.name}>
                      {!item.children ? (
                        <NavLink to={item.href}>
                          {({ isActive }) => (
                            <span
                              className={classNames(
                                isActive
                                  ? "bg-[#EEF2FF] text-gray-700"
                                  : "text-gray-500 hover:text-gray-700 hover:bg-[#EEF2FF]",
                                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              )}
                            >
                              <item.icon
                                className="h-6 w-6 shrink-0"
                                aria-hidden="true"
                              />
                              {item.name}
                            </span>
                          )}
                        </NavLink>
                      ) : (
                        <Disclosure as="div">
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={classNames(
                                  item.current
                                    ? "bg-[#EEF2FF] text-gray-700"
                                    : "text-gray-500 hover:text-gray-700 hover:bg-[#EEF2FF]",
                                  "flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold"
                                )}
                              >
                                <item.icon
                                  className="h-6 w-6 shrink-0"
                                  aria-hidden="true"
                                />
                                {item.name}
                                <ChevronRightIcon
                                  className={classNames(
                                    open
                                      ? "rotate-90 text-gray-700"
                                      : "text-gray-500",
                                    "ml-auto h-5 w-5 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel as="ul" className="mt-1 px-2">
                                {item.children.map((subItem: any) => (
                                  <li key={subItem.name}>
                                    {/* 44px */}
                                    <NavLink to={subItem.href}>
                                      {({ isActive }) => (
                                        <span
                                          className={classNames(
                                            isActive
                                              ? "bg-[#EEF2FF] text-gray-700"
                                              : "text-gray-500 hover:text-gray-700 hover:bg-[#EEF2FF]",
                                            "flex gap-x-3 rounded-md my-1 pr-2 pl-8 py-2 text-sm leading-6"
                                          )}
                                        >
                                          {subItem.icon && (
                                            <subItem.icon
                                              className="h-6 w-6 shrink-0"
                                              aria-hidden="true"
                                            />
                                          )}
                                          {subItem.name}
                                        </span>
                                      )}
                                    </NavLink>
                                  </li>
                                ))}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      )}
                    </li>
                  ))}
                </ul>
              </li>

              {![
                "WanAware_Finance",
                "Reseller_Finance",
                "Customer_Finance",
              ].includes(user?.role) && (
                <li className="mt-auto">
                  <NavLink
                    to="/support"
                    // className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                  >
                    {({ isActive }) => (
                      <span
                        className={classNames(
                          isActive
                            ? "bg-[#EEF2FF] text-gray-700"
                            : "text-gray-500 hover:text-gray-700 hover:bg-[#EEF2FF]",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <QuestionMarkCircleIcon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        />
                        Support
                      </span>
                    )}
                  </NavLink>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
