export const Roles = {
  super_user_roles: [
    {
      name: "WanAware_Super_User",
      value: "Admin",
    },
    {
      name: "WanAware_Engineer",
      value: "Engineer",
    },
    {
      name: "WanAware_Support",
      value: "Support",
    },
    {
      name: "WanAware_Report_User",
      value: "Report User",
    },
    {
      name: "WanAware_Finance",
      value: "Finance",
    },
  ],

  reseller_admin_roles: [
    {
      name: "Reseller_Admin",
      value: "Admin",
    },
    {
      name: "Reseller_Engineer",
      value: "Engineer",
    },
    {
      name: "Reseller_Support",
      value: "Support",
    },
    {
      name: "Reseller_Report_User",
      value: "Report User",
    },
    {
      name: "Reseller_Finance",
      value: "Finance",
    },
  ],

  customer_roles: [
    {
      name: "Customer_Admin",
      value: "Admin",
    },
    {
      name: "Customer_Engineer",
      value: "Engineer",
    },
    {
      name: "Customer_Report_User",
      value: "Report User",
    },
    {
      name: "Customer_Support",
      value: "Support",
    },
    {
      name: "Customer_Finance",
      value: "Finance",
    },
  ],
  company_user_roles: [
    {
      name: "Admin",
      value: "Admin",
    },
    {
      name: "Engineer",
      value: "Engineer",
    },
    {
      name: "Support",
      value: "Support",
    },
    {
      name: "Report_User",
      value: "Report User",
    },
    {
      name: "Finance",
      value: "Finance",
    },
  ],
};
