import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../NavBar/Navbar";
import GlobalToaster from "../../components/GlobalToaster";
import DeleteModal from "../../components/DeleteModal";
import Sidebar from "../Sidebar/Sidebar";
import InfoModal from "../../components/InfoModal";
import AlertMFASettings from "../../sections/Profile/AlertMFASettings";

function index() {
  return (
    <div className="h-full-vh">
      <Sidebar />
      <GlobalToaster />
      <DeleteModal />
      <InfoModal />
      <AlertMFASettings />
      <div className="lg:pl-60">
        <div className="sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white  px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8 dark:bg-gray-950 dark:text-gray-200">
          <Navbar />
        </div>
        <main className="py-10 relative">
          <div className="px-4 sm:px-6 lg:px-8 ">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
}

export default index;
