import React, { Fragment, useContext, useEffect, useState } from "react";

import {
  BuildingOffice2Icon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import SelectTenantModal from "./SelectTenantModal";
import { TenantsContext } from "../../contexts/TenantsContext";
import useAuth from "../../hooks/useAuth";
import { get } from "../../utils/httpMethods";

function TenantDropdown() {
  const { user, organization } = useAuth();
  const [openTenant, setOpenTenant] = useState(false);
  const {
    setSelectedTenant,
    selectedTenant,
    setSelectedTenantDetails,
    selectedTenantDetails,
  } = useContext(TenantsContext)!;
  const [tenantsList, setTenantsList] = useState<any>([]);
  const [current, setCurrent] = useState<any>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchTenants = async () => {
      setLoading(true);
      const SUB_URL =
        user?.role === "Reseller_Admin" ? `resellers/customers?limit=${Number.MAX_SAFE_INTEGER}` : `customers?limit=${Number.MAX_SAFE_INTEGER}`;
      try {
        const res = await get(`api/${SUB_URL}`);
        setTenantsList(res.data);
        let tenant =
          res?.data.find((ten: any) => ten.id === selectedTenant) ||
          organization;
        setSelectedTenant(tenant?.id);
        setSelectedTenantDetails(tenant);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    };
    fetchTenants();
  }, [user?.role]);

  // useEffect(() => {
  //   setSelectedTenant(
  //     tenantsList.length &&
  //       tenantsList.find(
  //         (ten: any) => ten.id === localStorage.getItem("selecetedTenant")
  //       )
  //   );
  // }, [tenantsList]);

  // useEffect(() => {
  //   setCurrent(
  //     tenantsList.find((ten: any) => ten.id === parseInt(selectedTenant)) ||
  //       organization
  //   );
  // }, [tenantsList, selectedTenant]);
  return (
    <>
      <button
        onClick={() => setOpenTenant(true)}
        type="button"
        className="font-medium text-sm text-center inline-flex items-center gap-x-2"
      >
        <>
          <BuildingOffice2Icon className="h-6 w-6 font-semibold" />
          <>
            <span>
              {!loading
                ? selectedTenantDetails?.name &&
                  selectedTenant !== organization.id
                  ? selectedTenantDetails?.name
                  : "Select Tenant"
                : ""}
            </span>
            <ChevronDownIcon className="h-4 w-4 font-semibold" />
          </>
        </>
      </button>
      <SelectTenantModal
        modalProps={{ open: openTenant, setOpen: setOpenTenant }}
        listProps={{ list: tenantsList, setList: setTenantsList }}
      />
    </>
  );
}

export default TenantDropdown;
