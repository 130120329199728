// import { getAuth } from 'firebase/auth'
import jwtDecode from "jwt-decode";
// routes
//
import axios from "./axios";
import { post } from "./httpMethods";

// ----------------------------------------------------------------------

const isValidToken = async (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode<{ exp: number }>(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleRefreshToken = (refreshToken: string) => {
  let token = "";

  post(`auth/refresh-token`, { refreshToken: refreshToken })
    .then((res: any) => {
      token = res.tokenData.id_token;
      setSession(token);
    })
    .catch((error) => console.error(error));
};

const handleTokenExpired = (exp: number) => {
  const currentTime = Date.now();
  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime // ~10s for testing timer logic
  const style =
    "background-color: darkblue ; color: white; font-style: italic; border: 5px solid  hotpink ; font-size: 1.5em; padding:6px;";

  // default token expiry is 60 minutes from firebase, we will refresh 5 minutes before the token expires to refresh the accessToken and keep the user logged in!
  const timeLeft = exp * 1000 - currentTime - 300000;

  setTimeout(() => {
    const refreshToken = localStorage.getItem("refreshToken");
    let token = "";
    post(`/api/auth/refresh-token`, { refreshToken: refreshToken })
      .then((res: any) => {
        token = res.tokenData.id_token;

        setSession(token);
      })
      .catch((error) => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("currentUser");
        localStorage.removeItem("selectedTenant");
        window.location.reload();
      });
  }, timeLeft);

  // clearTimeout(expiredTimer)
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    const { exp } = jwtDecode<{ exp: number }>(accessToken); // ~3 days by minimals server

    handleTokenExpired(exp);
  } else {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("selectedTenant");
    delete axios.defaults.headers.common.Authorization;
  }
};

const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem("refreshToken", refreshToken);
};

export { isValidToken, setSession, setRefreshToken, handleRefreshToken };
