import {
  DocumentMagnifyingGlassIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import React from "react";

interface Props {
  name: string;
  colspan: number;
}

const iconSize = 120;

function NoDataFound({ name, colspan }: Props) {
  return (
    <tr>
      <td colSpan={colspan}>
        <div className="my-4 mx-4 flex items-center justify-center py-20 space-x-10">
          <EyeSlashIcon height={iconSize} width={iconSize} />
          <span className="font-bold  text-3xl">{`No ${name} found`}</span>
        </div>
      </td>
    </tr>
  );
}

export default NoDataFound;
