import React from "react";
import UsersTable from "./UsersTable";
import useAuth from "../../../hooks/useAuth";
import { Roles } from "../../../constants/Roles";
import { useLocation } from "react-router-dom";

const menuItems = [
  {
    label: "Resend Invite",
    title: "Resend Invite",
    actionFunction: "handleResendInvite",
  },
  {
    label: "Edit",
    title: "Edit",
    actionFunction: "handleEdit",
  },
];

function Users({ url }: any) {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const roles =
    pathname === "/administration/users"
      ? Roles.customer_roles
      : user?.role === "Reseller_Admin"
      ? Roles.reseller_admin_roles
      : user?.role === "Customer_Admin"
      ? Roles.customer_roles
      : Roles.super_user_roles;
  return (
    <div className="border-b border-gray-200 py-5 mt-6 rounded">
      <UsersTable url={url} menuItems={menuItems} roles={roles} />
    </div>
  );
}

export default Users;
