import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { get } from "../utils/httpMethods";
import useAuth from "../hooks/useAuth";
import { TenantsContext } from "./TenantsContext";

export interface LayoutContextType {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;

  addedWidgets: any;
  setAddedWidgets: React.Dispatch<React.SetStateAction<any>>;

  layoutOnly: any;
  setLayoutOnly: React.Dispatch<React.SetStateAction<any>>;

  getWidgets: () => void;
}

export interface WidgetType {
  lastAdded: {
    id: number;
    title: string;
    text: string;
    type: string;
    path?: string;
  } | null;

  accepts: string;
}

export type BinType = WidgetType[];

export const LayoutContext = createContext<LayoutContextType | null>(null);

function LayoutProvider({ children }: { children: ReactNode }) {
  const { user, organization } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [addedWidgets, setAddedWidgets] = useState<any>();
  const { selectedTenant, selectedTenantDetails } = useContext(TenantsContext)!;

  // special object required to compare arrays and pass to the library, iterating and sanitizing non required fields from every widget row
  const [layoutOnly, setLayoutOnly] = useState<any>();

  const getWidgets = () => {
    const apiUrl = organization.access_tier === "Customer" ? `/api/widgets` : `/api/widgets/organization/${selectedTenant}`
    get(apiUrl)
      .then((res: any) => {
        setAddedWidgets(res);
        setLayoutOnly({
          lg: [
            ...res?.map((widget: any) => ({
              x: widget.x,
              y: widget.y,
              w: widget.w,
              h: widget.h,
              i: widget.id.toString(),
              minH: widget.minH,
              minW: widget.minW,
            })),
          ],
          md: [
            ...res?.map((widget: any) => ({
              x: widget.x,
              y: widget.y,
              w: widget.w,
              h: widget.h,
              i: widget.id.toString(),
              minH: widget.minH,
              minW: widget.minW,
            })),
          ],
          sm: [
            ...res?.map((widget: any) => ({
              x: widget.x,
              y: widget.y,
              w: widget.w,
              h: widget.h,
              i: widget.id.toString(),
              minH: widget.minH,
              minW: widget.minW,
            })),
          ],
          xs: [
            ...res?.map((widget: any) => ({
              x: widget.x,
              y: widget.y,
              w: widget.w,
              h: widget.h,
              i: widget.id.toString(),
              minH: widget.minH,
              minW: widget.minW,
            })),
          ],
        });
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (!!user) {
      getWidgets();
    }
  }, [user, selectedTenant]);

  useEffect(() => {
    localStorage.setItem("widgets", JSON.stringify(addedWidgets));
  }, [addedWidgets]);

  return (
    <LayoutContext.Provider
      value={{
        sidebarOpen,
        setSidebarOpen,
        addedWidgets,
        setAddedWidgets,
        getWidgets,
        layoutOnly,
        setLayoutOnly,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

export default LayoutProvider;
