import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import LoadingButton from "../../components/LoadingButton";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import LabelledInput from "../../components/LabelledInput";
import useReacaptcha from "../../hooks/useReacaptcha";
import WhiteLabelLogo from "./WhiteLabelLogo";
import { useNavigate } from "react-router-dom";

type Inputs = {
  otp: string;
};

function VerifyOTP() {
  const loginSchema = yup
    .object({
      otp: yup.string().required("Please enter code"),
    })
    .required();

  const methods = useForm<Inputs>({
    resolver: yupResolver(loginSchema),
  });

  const [useBackupCode, setUseBackupCode] = useState(false);

  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = methods;

  const navigate = useNavigate();
  const { isProcessing, verifyOTP, MFA } = useAuth();

  const { recaptchaVerify } = useReacaptcha("login");

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const token = await recaptchaVerify();

    if (token.length) {
      try {
        await verifyOTP(data.otp, token, useBackupCode);
      } catch (e: any) {
        setError("otp", { type: "custom", message: e.message });
      }
    }
  };

  useEffect(() => {
    if (!MFA || !MFA.mfa_hash) {
      navigate("/auth/login");
    }
  }, [MFA]);

  return (
    <div className="flex h-full-vh flex-1 flex-col justify-center sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <WhiteLabelLogo />
        <h2 className="mt-4 text-center text-xl leading-9 tracking-tight text-gray-900">
          Two-Factor authentication
        </h2>
        <div className="mt-2 text-gray-500 text-sm text-center">
          {useBackupCode
            ? "Enter the 6-digit backup code saved by you to confirm your action."
            : "Enter the 6-digit code generated by your app to confirm your action."}{" "}
          (Do not refresh this page)
        </div>
      </div>

      <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <FormProvider {...methods}>
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <LabelledInput
                name="otp"
                placeholder=""
                label={useBackupCode ? "Enter Backup Code" : "Enter OTP"}
                type="text"
              />
              <div>
                <LoadingButton
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-sky-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                  buttonText="Verify"
                  loading={isProcessing}
                />
              </div>
            </form>
          </FormProvider>
        </div>
        <p className="mt-10 text-center text-sm text-gray-500">
          {useBackupCode ? `Found your device?` : `Can't find your device?`}{" "}
          <a
            href="#!"
            onClick={() => setUseBackupCode(!useBackupCode)}
            className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
          >
            {useBackupCode ? "Use OTP" : "Use backup code."}
          </a>
        </p>
        <p className="mt-2 text-center text-sm text-gray-500">
          Not sure about anything?{" "}
          <a
            href="/auth/login"
            className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
          >
            Return to login
          </a>
        </p>
      </div>
    </div>
  );
}

export default VerifyOTP;
