import React, { Fragment, useContext, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LabelledInput from "../../../components/LabelledInput";
import LabelledList from "../../../components/LabelledList";
import { post } from "../../../utils/httpMethods";
import LoadingButton from "../../../components/LoadingButton";
import useAuth from "../../../hooks/useAuth";
import { GlobalToasterContext } from "../../../contexts/ToasterContext";

interface addUserModalProps {
  refetch: () => Promise<void>;
  modalProps: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
  url: string;
  roles: Array<any>;
  orgId: string;
}

type Inputs = {
  first_name: string;
  last_name: string;
  email: string;
  role: string;
};

const addUserSchema = yup
  .object({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    email: yup.string().required("Email is required"),
    role: yup.string().required("Role is required"),
  })
  .required();

function CreateUserModal({
  refetch,
  modalProps: { open, setOpen },
  url,
  roles,
  orgId,
}: addUserModalProps) {
  const cancelButtonRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { organization } = useAuth();
  const { setToasterProps } = useContext(GlobalToasterContext)!;

  const methods = useForm<Inputs>({
    resolver: yupResolver(addUserSchema),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setLoading(true);
      await post(url || `/api/users/${orgId}`, data);
      reset();
      refetch();
      handleClose();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setToasterProps({
        show: true,
        message: error.error,
        severity: "error",
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    reset();
    // setInterval(() => {
    //   reset();
    // }, 1000);
  };

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={handleClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto ">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-5/6 sm:w-10/12 md:w-8/12 lg:w-4/6 xl:w-3/6 sm:p-6">
                  <div>
                    <Dialog.Title
                      as="h2"
                      className="text-lg font-bold leading-6 text-gray-900 mb-3"
                    >
                      {organization.access_tier === "WanAware" || organization.access_tier === "Reseller" ? "Create Employee" : "Create User"}
                    </Dialog.Title>
                    <FormProvider {...methods}>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="grid grid-cols-2 gap-x-6 gap-y-4">
                          <LabelledInput
                            name="first_name"
                            placeholder="Enter First Name"
                            label="First name"
                            type="text"
                          />
                          <LabelledInput
                            name="last_name"
                            placeholder="Enter Last Name"
                            label="Last name"
                            type="text"
                          />
                          <LabelledInput
                            name="email"
                            placeholder="Enter Email"
                            label="Email"
                            type="text"
                          />
                          <LabelledList
                            options={roles}
                            label="Role"
                            name="role"
                            error={errors.role}
                          />
                        </div>

                        <div className="flex mt-8 space-x-2 justify-end">
                          <button
                            type="button"
                            onClick={() => handleClose()}
                            className="rounded-md bg-primary-50 px-2.5 py-1.5 text-sm font-semibold text-primary-600 shadow-sm hover:bg-primary-100"
                          >
                            Cancel
                          </button>

                          <LoadingButton
                            type="submit"
                            className="rounded-md bg-primary-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 disabled:bg-primary-100"
                            buttonText={organization.access_tier === "WanAware" || organization.access_tier === "Reseller" ? "Invite Employee" : "Invite User"}
                            loading={loading}
                          />
                        </div>
                      </form>
                    </FormProvider>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default CreateUserModal;
