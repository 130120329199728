import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

import TenantList from "./TenantList";
import { TenantsContext } from "../../contexts/TenantsContext";
interface modalProps {
  modalProps: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
  listProps: {
    list: any[];
    setList: React.Dispatch<any>;
  };
}

function SelectTenantModal({
  modalProps: { open, setOpen },
  listProps: { list, setList },
}: modalProps) {
  const { setSelectedTenant, setSelectedTenantDetails } =
    useContext(TenantsContext)!;
  const cancelButtonRef = useRef(null);
  const [selected, setSelected] = useState<any>();
  const [search, setSearch] = useState("");

  const handleSelect = () => {
    setSelectedTenantDetails(selected);
    setSelectedTenant(selected?.id);
    handleClose();
  };

  const handleClose = () => {
    setSearch("");
    setOpen(false);
  };

  return (
    <div>
      {" "}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={handleClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto ">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-2/6 sm:p-6">
                  <div>
                    <Dialog.Title
                      as="h2"
                      className="text-lg font-bold leading-6 text-gray-900 mb-4"
                    >
                      Select Tenant
                    </Dialog.Title>

                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <MagnifyingGlassIcon
                          className="pointer-events-none absolute inset-y-0 left-3 h-full w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        type="text"
                        id="table-search-users"
                        className="block p-3 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-full bg-gray-50 focus:ring-blue-500 focus:border-blue-500  dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none"
                        placeholder="Search for Tenants"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <TenantList
                      listProps={{ list: list, setList: setList }}
                      setSelected={setSelected}
                      searchTerm={search}
                    />
                    <div className="whitespace-nowrap py-4 pl-4 text-sm font-medium text-gray-900 flex space-x-4 justify-end">
                      <button
                        type="button"
                        onClick={() => setOpen(false)}
                        className="rounded-md bg-primary-50 px-2.5 py-1.5 text-sm font-semibold text-primary-600 shadow-sm hover:bg-primary-100"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="rounded-md bg-primary-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 disabled:bg-primary-100"
                        onClick={() => handleSelect()}
                      >
                        Select
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default SelectTenantModal;
