import React from "react";
function Billing() {
  return (
    <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 mt-6 rounded">
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-6 sm:px-4 md:grid-cols-3 lg:px-6">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Billing Information
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            This is your billing page
          </p>
        </div>
      </div>
    </div>
  );
}

export default Billing;
