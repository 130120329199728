import React from "react";
import SimpleBar, { Props as ScrollbarProps } from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
interface Props extends ScrollbarProps {
  sx?: any;
  children?: React.ReactNode;
}
function Scrollbar({ children, sx, ...other }: Props) {
  return <SimpleBar style={{ ...sx }}>{children}</SimpleBar>;
}

export default Scrollbar;
