import React from "react";

interface Props {
  colspan: number;
}

function LoadingRow({ colspan }: Props) {
  return (
    <>
      <tr>
        <td colSpan={colspan}>
          <div className="animate-pulse my-4 mx-4">
            <div className="h-6 bg-gray-400 mt-3 mb-6 rounded"></div>
            <div className="h-6 bg-gray-300 mb-6 rounded"></div>
            <div className="h-6 bg-gray-400 mb-6 rounded"></div>
          </div>
        </td>
      </tr>
    </>
  );
}

export default LoadingRow;
