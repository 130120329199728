import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LabelledInput from "../../../components/LabelledInput";
import LabelledList from "../../../components/LabelledList";
import { get, post, put } from "../../../utils/httpMethods";
import LoadingButton from "../../../components/LoadingButton";
import useAuth from "../../../hooks/useAuth";
import { GlobalToasterContext } from "../../../contexts/ToasterContext";
import { useLocation } from "react-router-dom";
import RHFCheckbox from "../../../components/RHFCheckbox";
import Scrollbar from "../../../components/Scrollbar";

interface AssignCustomerModalProps {
    modalProps: {
        open: boolean;
        setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    };
    user: any;
    fetchUsers: any;
    getCustomersAssignedToUsers: any
    allCustomers: any
}

export default function AssignCustomerModal({
    modalProps: { open, setOpen },
    user,
    fetchUsers,
    getCustomersAssignedToUsers,
    allCustomers,
}: AssignCustomerModalProps) {
    const cancelButtonRef = useRef(null);
    const { user: loggedUser, organization } = useAuth();
    const [loading, setLoading] = useState(false);
    const { setToasterProps } = useContext(GlobalToasterContext)!;
    const [tenants, setTenants] = useState(allCustomers);
    const { pathname } = useLocation();

    const onSubmit = async () => {
        let toDeleteUsers;
        let toSaveUsers: any = [];
        tenants.forEach((tenant: any) => {
            if (!tenant.id && tenant.checked) {
                const tempObj = {
                    user_id: user.id,
                    customer_id: tenant.value,
                    customer_name: tenant.label,
                    role: user.role,
                    organization_id: organization.id,
                }
                toSaveUsers.push(tempObj);
            }
        })
        toDeleteUsers = tenants.filter((tenant: any) => tenant.id && !tenant.checked)
        try {
            setLoading(true);
            await post(`/api/employee-customers`, { toSaveUsers, toDeleteUsers })
            setToasterProps({
                show: true,
                message: `Tenants for ${user.first_name + " " + user.last_name ?? "user"} changed`,
                severity: "success",
            });
            setLoading(false);
            setOpen(false);
            // fetchUsers();
            getCustomersAssignedToUsers();
        } catch (error: any) {
            setToasterProps({
                show: true,
                message: error.message,
                severity: "error",
            });
            setLoading(false);
            setOpen(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setInterval(() => {
            // reset();
        }, 1000);
    };

    const handleFieldCheck = (e: any, field: any) => {
        // setSelectionError("");
        // setSelectAll(false);
        let tempFields = tenants.map((eachField: any) => {
            if (eachField.value === field.value) {
                return {
                    ...field,
                    checked: !eachField.checked
                }
            } else {
                return eachField;
            }
        })
        setTenants(tempFields);
    }

    useEffect(() => {
        setTenants(allCustomers)
    }, [allCustomers]);

    return (
        <div>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRef}
                    onClose={handleClose}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto ">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-3/6 sm:p-6">
                                    <div>
                                        <Dialog.Title
                                            as="h2"
                                            className="text-lg font-bold mb-8 leading-6 text-gray-900 mb-3"
                                        >
                                            {pathname === "/super-user/resellers" ? ` Assigned to ${user.first_name} ${user.last_name}` : ` Assign to ${user.first_name} ${user.last_name}?`}
                                        </Dialog.Title>
                                        <Scrollbar sx={{ maxHeight: "70vh" }}>
                                            {
                                                pathname === "/super-user/resellers"
                                                    ? tenants && tenants.length && tenants?.filter((field: any) => field.checked)?.length
                                                        ? tenants?.filter((field: any) => field.checked).map((field: any) => (
                                                            <div
                                                                className={`relative mb-4 items-start pb-1 flex ${pathname === "/super-user/resellers" ? "cursor-default" : "cursor-pointer"}`}
                                                            >
                                                                <div className="flex h-6 items-center focus:ring-0">
                                                                    <input
                                                                        id={field.value}
                                                                        aria-describedby="comments-description"
                                                                        type="checkbox"
                                                                        disabled={pathname === "/super-user/resellers"}
                                                                        checked={field.checked}
                                                                        className={`h-4 w-4 ${pathname === "/super-user/resellers" ? "cursor-default" : "cursor-pointer"} rounded border-gray-300 text-primary-600 focus:ring-transparent ring-white focus:outline-none focus-visible:outline-none`}
                                                                        onClick={(e) => handleFieldCheck(e, field)}
                                                                    />
                                                                </div>
                                                                <div className={`ml-3 text-sm leading-6 ${pathname === "/super-user/resellers" ? "cursor-default" : "cursor-pointer"}`}>
                                                                    <label htmlFor={field.value} className={`font-medium text-gray-900 ${pathname === "/super-user/resellers" ? "cursor-default" : "cursor-pointer"}`}>
                                                                        {field.label}
                                                                    </label>
                                                                </div>
                                                            </div>))
                                                        : (
                                                            <div>No Customers Assigned</div>



                                                        )
                                                    :
                                                    tenants && tenants.length
                                                        ? tenants?.map((field: any) => (
                                                            <div
                                                                className={`relative mb-4 items-start pb-1 flex ${pathname === "/super-user/resellers" ? "cursor-default" : "cursor-pointer"}`}
                                                            >
                                                                <div className="flex h-6 items-center focus:ring-0">
                                                                    <input
                                                                        id={field.value}
                                                                        aria-describedby="comments-description"
                                                                        type="checkbox"
                                                                        disabled={pathname === "/super-user/resellers"}
                                                                        checked={field.checked}
                                                                        className={`h-4 w-4 ${pathname === "/super-user/resellers" ? "cursor-default" : "cursor-pointer"} rounded border-gray-300 text-primary-600 focus:ring-transparent ring-white focus:outline-none focus-visible:outline-none`}
                                                                        onClick={(e) => handleFieldCheck(e, field)}
                                                                    />
                                                                </div>
                                                                <div className={`ml-3 text-sm leading-6 ${pathname === "/super-user/resellers" ? "cursor-default" : "cursor-pointer"}`}>
                                                                    <label htmlFor={field.value} className={`font-medium text-gray-900 ${pathname === "/super-user/resellers" ? "cursor-default" : "cursor-pointer"}`}>
                                                                        {field.label}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        ))

                                                        : (
                                                            <div>No Customers Found</div>
                                                        )
                                            }
                                        </Scrollbar>

                                        <div className="flex mt-4 space-x-2 justify-end">
                                            <button
                                                type="button"
                                                onClick={() => handleClose()}
                                                className="rounded-md bg-primary-50 px-2.5 py-1.5 text-sm font-semibold text-primary-600 shadow-sm hover:bg-primary-100"
                                            >
                                                Cancel
                                            </button>

                                            {pathname !== "/super-user/resellers" && <LoadingButton
                                                onClick={onSubmit}
                                                type="submit"
                                                className="rounded-md bg-primary-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 disabled:bg-primary-100"
                                                buttonText="Save"
                                                loading={loading}
                                            />}
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div >
                </Dialog >
            </Transition.Root >
        </div >
    );
}
