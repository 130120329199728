import React, { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
interface ProtectedRouteProps {
  element: any;
  allowedRoles: string[];
}
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element,
  allowedRoles,
}) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {}, [user]);
  if (allowedRoles.includes(user?.role)) {
    return element;
  } else {
    user && navigate("/404");
  }
};

export default ProtectedRoute;
