import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { GlobalToasterContext } from "../../contexts/ToasterContext";
import useAuth from "../../hooks/useAuth";
import MfaSettings from "./MfaSettings";

function AlertMFASettings() {
  const {
    alertMFAModalProps: { show },
    setAlertMFAModalProps,
  } = useContext(GlobalToasterContext)!;
  const { logout } = useAuth();
  const cancelButtonRef = useRef(null);
  const [openMfaSettings, setOpenMfaSettings] = useState(false);

  const handleClose = () => {
    setAlertMFAModalProps((prev) => ({
      ...prev,
      show: false,
    }));
  };

  return (
    <div>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => {}}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto ">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white p-7 text-left shadow-xl transition-all sm:my-8 w-5/6 sm:w-10/12 md:w-8/12 lg:w-4/6 xl:w-3/6 ">
                  <div>
                    <Dialog.Title
                      as="h2"
                      className="text-lg font-bold leading-6 text-gray-900 mb-3"
                    >
                      Multi-Factor Authentication (MFA) Required
                    </Dialog.Title>
                    <div className="flex flex-col gap-4 text-sm leading-6 text-gray-500 mt-5">
                      <span className="">
                        Multi-Factor Authentication (MFA) is required to be
                        enabled for all user accounts in order to utilize this
                        portal. We currently support Google Authenticator which
                        can be enabled from the users My Profile {`>>`} Security
                        and then enable Multi-Factor Authentication.
                      </span>
                    </div>
                  </div>

                  <div className="flex mt-9 space-x-2 justify-end">
                    <button
                      type="button"
                      onClick={() => logout()}
                      className="rounded-md bg-primary-50 px-2.5 py-1.5 text-sm font-semibold text-primary-600 shadow-sm hover:bg-primary-100"
                    >
                      Logout
                    </button>
                    <button
                      type="button"
                      className="rounded-md bg-primary-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 disabled:bg-primary-100"
                      onClick={() => {
                        handleClose();
                        setOpenMfaSettings(true);
                      }}
                    >
                      Setup MFA
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <MfaSettings
        modalProps={{ open: openMfaSettings, setOpen: setOpenMfaSettings }}
      />
    </div>
  );
}

export default AlertMFASettings;
