import axios from "axios";
import { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_HOST_API_URL,
});

//////////////////////////////
//       Interceptors       //
//////////////////////////////

/*
 * Handle pre request
 * 1. Retrieve auth token from local storage and append in header if exists
 * 2. Return response
 */
export function handlePreRequest(config: AxiosRequestConfig & any) {
  const token = localStorage.getItem("accessToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}

/*
 * Handle request error while sending any data to API
 */
export function handleRequestError(error: AxiosError) {
  return Promise.reject(error);
}

/*
 * Handle response Success
 */
export function handleResponseSuccess(response: AxiosResponse) {
  return response.data;
}

const logout = () => {
  // Logout from Firebase Auth
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("currentUser");
  localStorage.removeItem("selectedTenant");
  window.location.reload();
};

/*
 * Handle response Error
 * 1. Check for 401 and 403 response code from server and logout
 */
export async function handleResponseError(error: AxiosError) {
  if (error.response && [401].includes(error.response.status)) {
    if (!window.location.href.includes("auth")) {
      await logout();
    }
  }
  return Promise.reject(
    (error && error.response && error.response.data) || error
  );
}

/*
 * Interceptors for request and response
 * 1. Handle pre request data
 * 2. Handle request error if any
 * 3. Handle response success
 * 4. Handle response error
 */
axiosInstance.interceptors.request.use(handlePreRequest, handleRequestError);
axiosInstance.interceptors.response.use(
  handleResponseSuccess,
  handleResponseError
);

export default axiosInstance;
