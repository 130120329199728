import React, { useContext, useEffect, useState } from "react";
import UsersTableRow from "./UsersTableRow";
import CreateUserModal from "./CreateUserModal";
import { get } from "../../../utils/httpMethods";
import useAuth from "../../../hooks/useAuth";
import LoadingRow from "../../../components/LoadingRow";
import { AuthContext } from "../../../contexts/JWTContext";
import Scrollbar from "../../../components/Scrollbar";
import NoDataFull from "../../../components/NoDataFull";
import NoDataFound from "../../../components/NoDataFound";
import { TenantsContext } from "../../../contexts/TenantsContext";
import { useLocation } from "react-router";
import { ResellersContext } from "../../../contexts/ResellerContext";

function UsersTable({ url, menuItems, roles, currentOrganizationId }: any) {
  const { user: loggedUser, organization: loggedOrg } = useAuth();
  const { pathname } = useLocation();
  const tableCols =
    pathname.includes("/super-user/resellers") ||
      pathname.includes("/super-user/customers") ||
      (pathname === "/administration/users" &&
        loggedUser?.role !== "Customer_Admin")
      ? ["ID", "Name", "Email", "Status", ""]
      : ["ID", "Name", "Email", "Status", "Actions", ""];
  const [openUserModal, setOpenUserModal] = useState(false);
  const [allUsers, setAllUsers] = useState([]) as any;
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const { organization } = useContext(AuthContext)!;
  const { selectedTenant } = useContext(TenantsContext)!;
  const [companies, setCompanies] = useState([]);
  const [orgId, setOrgId] = useState<any>();
  const { currentCompany } = useContext(ResellersContext)!;

  useEffect(() => {
    const isTenant = pathname.split("/").includes("administration");
    const org = isTenant ? selectedTenant : organization?.id;
    setOrgId(org);
  }, [pathname, organization, selectedTenant]);
  const [allCustomersOrganizations, setAllCustomerOrganizations] = useState([]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      let result: any = await get(
        url ? url : `/api/users/organization/${orgId}`
      );
      setAllUsers(result?.data);
      setLoading(false);
      setSearch("");
    } catch (error: any) {
      console.log(error);
      setLoading(false);
    }
  };

  // Get all comapnies within organization
  const getAllCompanies = async () => {
    const result: any = await get(
      `/api/companies/${currentOrganizationId ? currentOrganizationId : orgId
      }?noTree=true`
    );
    const temp = result.map((eachCompany: any) => {
      return {
        value: eachCompany.id,
        label: eachCompany.name,
      };
    });
    setCompanies(temp);
  };

  const getAllCustomerOrganizations = async () => {
    if (!["WanAware_Super_User", "Reseller_Admin"].includes(loggedUser?.role)) {
      return;
    }
    const url =
      loggedUser.role === "WanAware_Super_User" && pathname !== "/super-user/resellers"
        ? `/api/customers?limit=${Number.MAX_SAFE_INTEGER}`
        : `/api/resellers/${currentCompany?.id ? currentCompany?.id : organization?.id}/customers?limit=${Number.MAX_SAFE_INTEGER}`;
    const result: any = await get(url);
    setAllCustomerOrganizations(result.data);
    return result.data;
  };

  useEffect(() => {
    // organization && fetchUsers();
    if (url || orgId) {
      fetchUsers();
      getAllCustomerOrganizations();
      getAllCompanies();
    }
  }, [url, orgId]);

  const handleSearchUser = async () => {
    setLoading(true);
    try {
      let result: any = await get(
        url
          ? url + `?search=${search}`
          : `/api/users/organization/${orgId}?search=${search}`
      );
      setAllUsers(result.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="mt-3 flow-root relative">
        <div className="flex justify-between">
          <div className="flex items-center space-x-2 ">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="table-search-users"
                className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500  dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none"
                placeholder={loggedOrg?.access_tier === "Customer" || pathname.includes("/super-user/customers") ? "Search for Users" : "Search for Employees"}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            <button
              type="button"
              className="rounded-md bg-sky-50 px-2.5 py-1.5 text-sm font-semibold text-sky-600 shadow-sm hover:bg-sky-100"
              onClick={handleSearchUser}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </button>

            <button
              type="button"
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-sky-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => {
                setSearch("");
                fetchUsers();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </button>
          </div>
          {(pathname === "/super-user/wanaware-admin/employees" ||
            pathname === "/settings/users" ||
            pathname === "/settings/employees" ||
            (pathname === "/administration/users" &&
              loggedUser?.role === "Customer_Admin")) && (
              <div className="flex space-x-2">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 sm:ml-3 sm:w-auto"
                  onClick={() => setOpenUserModal(true)}
                >
                  {loggedOrg?.access_tier === "Customer" ? "Create User" : "Create Employee"}
                </button>
              </div>
            )}
        </div>

        {/* table */}
        <div>
          <div className="mt-5 flow-root">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className=" shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        {tableCols.map((col) => (
                          <th
                            key={col}
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            {col}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {loading ? (
                        <LoadingRow colspan={tableCols.length} />
                      ) : (
                        allUsers &&
                        allUsers.map((user: any) => (
                          <UsersTableRow
                            user={user}
                            fetchUsers={fetchUsers}
                            menuItems={menuItems}
                            roles={roles}
                            companies={companies}
                            allCustomersOrganizations={
                              allCustomersOrganizations
                            }
                            setAllCustomerOrganizations={
                              setAllCustomerOrganizations
                            }
                            getAllCustomerOrganizations={
                              getAllCustomerOrganizations
                            }
                          />
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateUserModal
        refetch={fetchUsers}
        modalProps={{ open: openUserModal, setOpen: setOpenUserModal }}
        url={url}
        roles={roles}
        orgId={orgId}
      />
    </div>
  );
}

export default UsersTable;
