import React from "react";

interface Props extends React.HTMLProps<HTMLButtonElement> {
  className?: string;
}

function Loader({ className }: Props) {
  return (
    <div
      className={`inline-block animate-spin rounded-full border-2 border-solid border-gray-400  border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] ${className}`}
    ></div>
  );
}

export default Loader;
