import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import Accordion from "../../../components/Accordion";
import LoadingButton from "../../../components/LoadingButton";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LabelledInput from "../../../components/LabelledInput";
import LabelledList from "../../../components/LabelledList";
import { GlobalToasterContext } from "../../../contexts/ToasterContext";
import { Roles } from "../../../constants/Roles";
import { del, get, post, put } from "../../../utils/httpMethods";
import Select from "react-select";
import { MinusCircleIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import useAuth from "../../../hooks/useAuth";
import EditUserModal from "./EditUserModal";
import { useLocation } from "react-router-dom";


type Inputs = {
    company: string;
    role: string;
};

const Reseller_Employee = [
    "Reseller_Admin",
    "Reseller_Engineer",
    "Reseller_Report_User",
    "Reseller_Support",
    "Reseller_Finance",
];

const addCompanyAndRoleSchema =
    yup.array().of(yup.object().shape({
        company: yup.string().required("Company is required"),
        role: yup.string().required("Role is required"),
    }))

export const AccordionTitle = ({ text }: { text: string }) => {
    return <span>{text}</span>;
};

function UserAccordion({ user, setOpenAccordion, companies, userAssignedCompanies, menuOptions, getUserAssignedCompanyAndRoles, handleClick, fetchUsers, setAssignCustomer, totalCustomersAssigned }: any) {
    const [open, setOpen] = useState(false);
    const { user: loggedUser } = useAuth();
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(false);
    const existingCompanies = userAssignedCompanies && userAssignedCompanies.length ? userAssignedCompanies.map((company: any) => company.company_id) : [];
    const [companyUsers, setCompanyUsers] = useState<any>(userAssignedCompanies && userAssignedCompanies.length ? userAssignedCompanies.map(({ created_at, ...restData }: any, index: any) => {
        return {
            ...restData,
            objId: index + 1
        }
    }) : [{
        objId: 1,
        company_id: "",
        role: "",
        user_id: user.id
    }])
    const [deletedRoles, setDeletedRoles] = useState<any>([]);
    const { setModalProps, setToasterProps } = useContext(GlobalToasterContext)!;

    const submitHandler = async () => {
        const tempUsers = companyUsers.map(({ objId, ...restData }: any) => restData).filter(({ company_id, role }: any) => company_id && role);
        // segregate company users according to old assigned company-role update and newly assigned company-role based on "id" property in companyUsers
        const toUpdateUsers = tempUsers.filter((each: any) => each.id);
        const toAddUsers = tempUsers.filter((each: any) => !each.id);
        const toDeleteUsers = [...deletedRoles];
        try {
            if (toAddUsers?.length || toUpdateUsers?.length || toDeleteUsers?.length) {
                setLoading(true);
                await post(`/api/company-users`, { toUpdateUsers, toAddUsers, toDeleteUsers });
                if (toUpdateUsers?.length) {
                    const parentCompanyRole = toUpdateUsers.find((user: any) => user.is_parent_company_role);
                    if (parentCompanyRole) {
                        const primaryUserRole = user.role.includes("WanAware") ? parentCompanyRole.role === `Admin` ? `WanAware_Super_User` : `WanAware_${parentCompanyRole.role}` : user.role.includes("Reseller") ? `Reseller_${parentCompanyRole.role}` : `Customer_${parentCompanyRole.role}`;
                        await put(`/api/users/${user.id}`, { role: primaryUserRole });
                        fetchUsers()
                    }
                }
                getUserAssignedCompanyAndRoles();
                handleClose();
                setLoading(false);
                setToasterProps({
                    show: true,
                    message: "User Updated Successfully",
                    severity: "success",
                });
            } else {
                throw new Error("Please enter valid company and role details")
            }
        } catch (error: any) {
            setLoading(false);
            setToasterProps({
                show: true,
                message: error.message,
                severity: "error",
            });
        }
    };

    const handleClose = () => {
        // reset();
        setOpenAccordion(false);
        // setInterval(() => {
        //   reset();
        // }, 1000);
    };

    const roles = Roles.company_user_roles.map((eachRole) => {
        return {
            value: eachRole.name,
            label: eachRole.value
        }
    });

    const addCompanyRoleHandler = () => {
        setCompanyUsers([...companyUsers, {
            objId: +companyUsers[companyUsers.length - 1].objId + 1,
            company_id: "",
            role: "",
            user_id: user.id
        }])
    }

    const onCompanyRoleChangeHandler = (label: string, value: any, id: any) => {
        const temp = companyUsers.map((each: any) => {
            if (each.objId == id) {
                return {
                    ...each,
                    [label]: value,
                }
            } else {
                return each;
            }
        })
        setCompanyUsers(temp);
    }


    const deleteCompanyRole = (objId: any, data: any) => {
        // Find roles to be deleted
        const deletedRolesTemp = companyUsers.filter((each: any) => ((each.objId == objId) && data.id));
        setDeletedRoles((prev: any) => [...prev, ...deletedRolesTemp]);
        const temp = companyUsers.filter((each: any) => each.objId != objId);
        if (!temp?.length) {
            setCompanyUsers([{
                objId: 1,
                company_id: "",
                role: "",
                user_id: user.id
            }])
        } else {
            setCompanyUsers(temp);
        }
    }

    const onDelete = (objId: any, data: any) => {
        setModalProps({
            show: true,
            title: "Delete Role",
            message: `Are you sure you want to delete?`,
            deleteFunction: () => deleteCompanyRole(objId, data),
        });
    };

    useEffect(() => {
        setCompanyUsers(userAssignedCompanies && userAssignedCompanies.length ? userAssignedCompanies.map(({ created_at, ...restData }: any, index: any) => {
            return {
                ...restData,
                objId: index + 1
            }
        }) : [{
            objId: 1,
            company_id: "",
            role: "",
            user_id: user.id
        }])
    }, [userAssignedCompanies])

    return (
        <>
            {user && (
                <div>
                    {(pathname === "/super-user/wanaware-admin/employees" || pathname === "/settings/users" || pathname === "/settings/employees" || (pathname === "/administration/users" && loggedUser.role === "Customer_Admin")) &&
                        <div className="flex mr-8 mt-4 mb-4 justify-end">
                            <Menu as="div" className="absolute z-50">
                                <Menu.Button className="-m-4 flex items-center p-1.5 ">
                                    <button
                                        id="dropdownMenuIconButton"
                                        onClick={() => setOpen(true)}
                                        data-dropdown-toggle="dropdownDots"
                                        aria-hidden="true"
                                        //   className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                                        type="button"
                                    >
                                        <EllipsisVerticalIcon className="w-5 h-5" aria-hidden="true" />
                                    </button>
                                </Menu.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-8 -mt-10 w-44 origin-top-right rounded-lg shadow bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                        {menuOptions.map((menu: any) => (
                                            <Menu.Item disabled={!userAssignedCompanies?.length && (menu?.title == "Edit Roles") ? true : false}>
                                                <div
                                                    onClick={() => handleClick(menu.actionFunction)}
                                                    className={`block px-4 py-2 text-sm text-gray-700 capitalize ${!userAssignedCompanies?.length && (menu.title == "Edit Roles") ? "cursor-default" : "cursor-pointer"} hover:bg-gray-100`}
                                                >
                                                    {menu.title}
                                                </div>
                                            </Menu.Item>
                                        ))}
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>}
                    {((pathname === "/super-user/wanaware-admin/employees" || pathname === "/super-user/resellers") && user.role !== "WanAware_Super_User" || ((pathname === "/settings/users" || pathname === "/settings/employees") && (loggedUser.role === "WanAware_Super_User" || loggedUser.role === "Reseller_Admin"))) &&
                        <div>
                            <button className="mb-4 text-sm text-blue-600 dark:text-blue-500 hover:underline" onClick={() => setAssignCustomer(true)}>{(user.role !== "WanAware_Super_User" && user.role !== "Reseller_Admin") ? ` ${totalCustomersAssigned ?? 0} ${totalCustomersAssigned === 1 ? "Customer" : "Customers"} Assigned` : ""}</button>
                        </div>}
                    {/* <div className="grid grid-cols-4 gap-x-4 gap-y-4"> */}
                    {
                        companyUsers.sort((a: any, b: any) => Number(b.is_parent_company_role) - Number(a.is_parent_company_role)).map(({ objId, ...eachCompanyUser }: any, index: any) => {
                            return (
                                <div className="pr-6 grid grid-cols-[1fr,1fr,auto] gap-x-4 mb-8 items-center">
                                    <div className="flex justify-between items-center">
                                        <label className="block text-sm font-medium text-left pr-3 pt-1 leading-6 text-gray-900">
                                            Company
                                        </label>
                                        <Select
                                            className="flex-grow mt-2 ring-primary-500 outline-none self-end text-gray-900 "
                                            // isMulti
                                            isDisabled={eachCompanyUser.id && eachCompanyUser.is_parent_company_role || pathname.includes("/super-user/resellers") || pathname.includes("/super-user/customers") || (pathname === "/administration/users" && loggedUser.role !== "Customer_Admin")}
                                            onChange={(e: any) => {
                                                onCompanyRoleChangeHandler("company_id", e.value, objId);
                                            }}
                                            placeholder="Select Company"
                                            value={companies.find((option: any) => option.value === companyUsers[index].company_id) ?? ""}
                                            options={companies.filter((company: any) => !existingCompanies.includes(company.value))}
                                        />
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <label className="block text-sm font-medium  text-left pl-2 pr-3 pt-1 leading-6 text-gray-900">
                                            Role
                                        </label>
                                        <Select
                                            isDisabled={pathname.includes("/super-user/resellers") || pathname.includes("/super-user/customers") || (pathname === "/administration/users" && loggedUser.role !== "Customer_Admin")}
                                            className={`grow mt-2 ring-primary-500 outline-none self-end text-gray-900 ${index !== companyUsers.length - 1 ? "mr-0" : ""}`}
                                            onChange={(e: any) => {
                                                onCompanyRoleChangeHandler("role", e.value, objId);
                                            }}
                                            placeholder="Select Role"
                                            value={roles.find((option: any) => option.value === companyUsers[index].role) ?? ""}
                                            options={roles}
                                        />

                                    </div>
                                    {(pathname === "/super-user/wanaware-admin/employees" || pathname === "/settings/users" || pathname === "/settings/employees" || (pathname === "/administration/users" && loggedUser.role === "Customer_Admin")) &&
                                        <div className="flex w-20">
                                            {(index !== 0 || !eachCompanyUser.id) &&
                                                <button
                                                    type="button"
                                                    disabled={!eachCompanyUser.id && companyUsers?.length == 1}
                                                    className="rounded-md bg-red-600 px-1 ml-3 mr-2 mt-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                                    onClick={() => deleteCompanyRole(objId, eachCompanyUser)}
                                                >
                                                    <TrashIcon className="text-white w-4 h-4" />
                                                </button>
                                            }
                                            {index === companyUsers.length - 1 &&

                                                <button
                                                    type="button"
                                                    // disabled={!eachCompanyUser.id && companyUsers?.length == 1}
                                                    className="rounded-md bg-primary-600 px-1 mt-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                                                    onClick={addCompanyRoleHandler}
                                                    disabled={index !== 0 && !(companyUsers[index]?.company_id && companyUsers[index]?.role)}
                                                >
                                                    <PlusIcon className="text-white h-4 w-4 " />

                                                </button>}
                                        </div>}
                                </div>
                            )
                        })

                    }


                    {(pathname === "/super-user/wanaware-admin/employees" || pathname === "/settings/users" || pathname === "/settings/employees" || (pathname === "/administration/users" && loggedUser.role === "Customer_Admin")) &&
                        <div className="flex space-x-2 justify-end pr-6">
                            <button
                                type="button"
                                onClick={() => handleClose()}
                                className="rounded-md bg-primary-50 px-2.5 py-1.5 text-sm font-semibold text-primary-600 shadow-sm hover:bg-primary-100"
                            >
                                Cancel
                            </button>

                            <LoadingButton
                                type="button"
                                onClick={submitHandler}
                                className="rounded-md bg-primary-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 disabled:bg-primary-100"
                                buttonText="Save"
                                loading={loading}
                            />
                        </div>}
                </div>

            )}
        </>
    );
}

export default UserAccordion;
