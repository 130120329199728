import React, { useState, useEffect } from "react";
// import { socket } from "../../socket";
import { ConnectionState } from "./ConnectionState";
import { ConnectionEvents } from "./ConnectionEvents";
// import { ConnectionManager } from "./ConnectionManager";

export default function MainCompTest() {
  // const [isConnected, setIsConnected] = useState(socket.connected);
  const [fooEvents, setFooEvents] = useState<any>([]);

  // useEffect(() => {
  //   function onConnect() {
  //     setIsConnected(true);
  //   }

  //   function onDisconnect() {
  //     setIsConnected(false);
  //   }

  //   function onFooEvent(value: any) {
  //     setFooEvents((previous: any) => [...previous, value]);
  //   }

  //   function logNew(value: any) {
  //     console.log(value);
  //   }

  //   socket.on("connect", onConnect);
  //   socket.on("disconnect", onDisconnect);
  //   socket.on("locationCreated", logNew);
  //   socket.on("foo", onFooEvent);

  //   return () => {
  //     socket.off("connect", onConnect);
  //     socket.off("disconnect", onDisconnect);
  //     socket.off("foo", onFooEvent);
  //     socket.off("locationCreated", logNew);
  //   };
  // }, []);

  return (
    <div className="App">
      {/* <button
        onClick={() =>
          socket.emit("locationCreated", {
            target_id: 2,
            worker_id: 3,
            interval_in_seconds: 15,
          })
        }
      >
        CLICK ME
      </button>
      <ConnectionState isConnected={isConnected} />
      <ConnectionEvents events={fooEvents} />
      <ConnectionManager /> */}
    </div>
  );
}
