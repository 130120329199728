import React, { useCallback, useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import logoDark from "../../assets/WanAware_Logo_Dark.png";

function WhiteLabelLogo({ setWhiteLabelData }: any) {
  const { getWhiteLabel } = useAuth();

  const [whiteLabel, setWhiteLabel] = useState({} as any);
  const [isLoadingWhiteLabel, setIsLoadingWhiteLabel] = useState(false);

  const fetchWhiteLabel = useCallback(async () => {
    setIsLoadingWhiteLabel(true);
    const whiteLabel = await getWhiteLabel();
    setWhiteLabel(whiteLabel as any);
    setWhiteLabelData && setWhiteLabelData(whiteLabel)
    setIsLoadingWhiteLabel(false);
  }, []);

  useEffect(() => {
    fetchWhiteLabel();
  }, []);

  return (
    <>
      {!isLoadingWhiteLabel ? (
        <img
          className="mx-auto w-40"
          src={whiteLabel?.logo || logoDark}
          alt={whiteLabel?.name || "WanAware"}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default WhiteLabelLogo;
