import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Users from "../Settings/Users/Users";
import { Cog8ToothIcon, LightBulbIcon } from "@heroicons/react/20/solid";
import ElementManagement from "./ElementManagement";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
function WanAwareAdmin() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const activeTab = pathname.split("/").slice(-1);

  const [currentTab, setCurrentTab] = useState(activeTab[0]);

  const tabs = [
    {
      name: "Employees",
      value: "employees",
      component: <Users />,
      icon: <Cog8ToothIcon width={20} height={20} />,
      url: "employees",
    },
    {
      name: "Element Management",
      value: "element-management",
      component: <ElementManagement />,
      icon: <LightBulbIcon width={20} height={20} />,
      url: `element-management`,
    },
  ];
  const onTabChange = (tab: any) => {
    setCurrentTab(tab.value);
    navigate(`/super-user/wanaware-admin/${tab.url}`);
  };

  return (
    <div>
      <div className="hidden sm:block mt-5 mb-5">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <div
                key={tab.name}
                // href={tab.href}
                className={classNames(
                  tab.value === currentTab
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium cursor-pointer"
                )}
                onClick={() => onTabChange(tab)}
              >
                <span className="inline-flex gap-2">
                  {tab.icon} <span> {tab.name}</span>
                </span>
              </div>
            ))}
          </nav>
        </div>
      </div>
      <div className="border-gray-300 pb-4">
        {tabs.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <div key={tab.name}>{tab.component}</div>;
        })}
      </div>
    </div>
  );
}

export default WanAwareAdmin;
