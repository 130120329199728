import React, { useContext, useState } from "react";
import { TenantsContext } from "../../contexts/TenantsContext";
interface Props {
  listProps: {
    list: any[];
    setList: React.Dispatch<any>;
  };
  setSelected: any;
  searchTerm: any;
}
function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
function TenantList({
  listProps: { list, setList },
  setSelected,
  searchTerm,
}: Props) {
  const [active, setActive] = useState<any>();

  return (
    <div>
      <div className="w-full h-[22rem] text-gray-900 bg-white mt-6 overflow-y-auto">
        {list.length ? (
          list
            .filter((item) =>
              item.name.toLowerCase().includes(searchTerm.toLowerCase().trim())
            )
            .map((item, i) => (
              <button
                onClick={() => {
                  setActive(i);
                  setSelected(item);
                }}
                type="button"
                className={classNames(
                  active === i ? "bg-primary-50 " : "",
                  "relative inline-flex items-center w-full px-4 py-2 text-sm hover:bg-primary-50"
                )}
              >
                {item.name}
              </button>
            ))
        ) : (
          <div>
            <div className="text-center">
              <h3 className="mt-2 text-sm font-semibold text-gray-900">
                No data found
              </h3>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TenantList;
