import React, { Fragment, useContext, useEffect, useState } from "react";
import EditableTableInput from "./EditableTableInput";
import DateInFormat from "../../../components/DateInFormat";
import { GlobalToasterContext } from "../../../contexts/ToasterContext";
import { del, get, post } from "../../../utils/httpMethods";
import LoadingButton from "../../../components/LoadingButton";
import Badge from "../../../components/Badge";
import LabelledSelect from "../../../components/LabelledSelect";
import useAuth from "../../../hooks/useAuth";
import { Menu, Transition } from "@headlessui/react";
import { ChevronUpIcon, EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import UpdateUserStatus from "../../Reseller/updateUserStatus";
import EditUserModal from "./EditUserModal";
import { Roles } from "../../../constants/Roles";
import UserAccordion from "./UserAccordion";
import { TrashIcon } from "@heroicons/react/24/outline";
import AssignCustomerModal from "./AssignCustomerModal";
import { useLocation } from "react-router-dom";
import Loader from "../../../components/Loader";
const roles = [
  ...Roles.customer_roles,
  ...Roles.reseller_admin_roles,
  ...Roles.super_user_roles,
];

const Reseller_Employee = [
  "Reseller_Admin",
  "Reseller_Engineer",
  "Reseller_Report_User",
  "Reseller_Support",
  "Reseller_Finance",
];

export const AccordionTitle = ({ text }: { text: string }) => {
  return <span>{text}</span>;
};

function UsersTableRow({
  user,
  fetchUsers,
  menuItems,
  companies,
  allCustomersOrganizations,
  getAllCustomerOrganizations,
}: any) {
  const allowedRoles = [
    "WanAware_Super_User",
    "Reseller_Admin",
    "Customer_Admin",
  ];
  const { user: loggedUser } = useAuth();
  const { pathname } = useLocation();
  const [edit, setedit] = useState(false);
  const [assignCustomer, setAssignCustomer] = useState(false);
  const [editRoles, setEditRoles] = useState(false);
  const { setModalProps, setToasterProps } = useContext(GlobalToasterContext)!;
  const [open, setOpen] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [userAssignedCompanies, setUserAssignedCompanies] = useState();
  const [allCustomers, setAllCustomers] = useState([]);
  const [totalCustomersAssigned, setTotalCustomersAssigned] = useState(0);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const menuOptions = user?.is_active
    ? menuItems.filter((item: any) => item.label !== "Resend Invite")
    : [...menuItems];
  const handleDeleteUser = async (id: any) => {
    try {
      setIsDeletingUser(true);
      const url =
        loggedUser.role === "Reseller_Admin"
          ? `/api/users/${id}`
          : Reseller_Employee.includes(user.role)
          ? `/api/resellers/users/${id}`
          : `/api/users/${id}`;
      const result: any = await del(url);
      setIsDeletingUser(false);

      setToasterProps({
        show: true,
        message: result.message,
        severity: "success",
      });
      fetchUsers();
    } catch (error: any) {
      setIsDeletingUser(false);
      setToasterProps({
        show: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  const ResendInviteUser = async () => {
    try {
      const url = `/api/users/invite/${user.id}`;
      const result: any = await post(url);
      setToasterProps({
        show: true,
        message: result.message,
        severity: "success",
      });
      fetchUsers();
    } catch (error: any) {
      setToasterProps({
        show: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  const globalFunction: Record<string, () => void> = {
    handleDelete: () => {
      setModalProps({
        show: true,
        title: "Delete User",
        message: `${user.first_name + " " + user.last_name} will be deleted!`,
        deleteFunction: async () => await handleDeleteUser(user.id),
      });
    },
    handleResendInvite: () => {
      ResendInviteUser();
    },
    handleEdit: () => {
      setedit(true);
      // handleEditUser();
    },
    handleEditRole: () => {
      setEditRoles(true);
      // handleEditUser();
    },
  };

  const handleClick = (functionName: any) => {
    globalFunction[functionName]();
  };

  const getUserAssignedCompanyAndRoles = async () => {
    const result: any = await get(`/api/company-users/${user.id}`);
    setUserAssignedCompanies(result);
  };

  // Get customers assigned to users/employees
  const getCustomersAssignedToUsers = async () => {
    // const customers = await getAllCustomerOrganizations();
    const result: any = await get(`/api/employee-customers/${user.id}`);

    const temp = allCustomersOrganizations.map((customer: any) => {
      const customerPresent = result.find(
        (each: any) => customer.id === each.customer_id
      );
      if (customerPresent) {
        return {
          value: customer.id,
          label: customer.name,
          checked: true,
          id: customerPresent.id,
        };
      } else {
        return {
          value: customer.id,
          label: customer.name,
          checked: false,
        };
      }
    });
    const totalAssigned = temp.filter((each: any) => each.checked);
    user.totalCustomersAssigned = totalAssigned.length;
    setTotalCustomersAssigned(totalAssigned.length);
    setAllCustomers(temp);
    return result;
  };

  useEffect(() => {
    getUserAssignedCompanyAndRoles();
    getCustomersAssignedToUsers();
  }, [allCustomersOrganizations]);

  return (
    <>
      <tr
        className={`${
          allowedRoles.includes(loggedUser.role)
            ? "cursor-pointer"
            : "cursor-default"
        }`}
        key={user.id}
        onClick={() => {
          if (allowedRoles.includes(loggedUser.role)) {
            setOpenAccordion(!openAccordion);
            setSelectedUser(user.id);
          } else return;
        }}
      >
        <td className={`py-4 pl-4 pr-3 text-sm font-medium text-gray-800`}>
          {user.id}
        </td>
        <td className={`py-4 pl-4 pr-3 text-sm font-medium text-gray-800`}>
          {user.first_name + " " + user.last_name ?? ""}
        </td>
        <td className={`py-4 pl-4 pr-3 text-sm font-medium text-gray-800`}>
          {user.email ?? ""}
        </td>
        <td className={`py-4 pl-4 pr-3 text-sm font-medium text-gray-800`}>
          {user.is_active ? (
            <Badge theme="light" variant="success" text={"Active"} />
          ) : (
            <Badge theme="light" variant="error" text={"Inactive"} />
          )}
        </td>
        {(pathname === "/super-user/wanaware-admin/employees" ||
          pathname === "/settings/users" ||
          pathname === "/settings/employees" ||
          (pathname === "/administration/users" &&
            loggedUser.role === "Customer_Admin")) && (
          <td className={`py-4 pl-4 pr-3 text-sm font-medium text-gray-800`}>
            {isDeletingUser ? (
              <span className="px-2 py-1.5">
                <Loader className="h-5 w-5 border-3 border-primary" />
              </span>
            ) : (
              <button
                type="button"
                className="rounded-md bg-red-600 px-1 ml-3 mr-2 mt-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                onClick={() => handleClick("handleDelete")}
                hidden={user.id === loggedUser.id}
              >
                <TrashIcon className="text-white w-4 h-4" />
              </button>
            )}
          </td>
        )}
        {!allowedRoles.includes(loggedUser.role) ? (
          pathname !== "/administration/users" ? (
            <td>
              <Menu as="div" className="absolute z-50">
                <Menu.Button className="-m-4 flex items-center p-1.5 ">
                  <button
                    id="dropdownMenuIconButton"
                    onClick={() => setOpen(true)}
                    data-dropdown-toggle="dropdownDots"
                    aria-hidden="true"
                    //   className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                    type="button"
                  >
                    <EllipsisVerticalIcon
                      className="w-5 h-5"
                      aria-hidden="true"
                    />
                  </button>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-8 -mt-10 w-44 origin-top-right rounded-lg shadow bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    {menuOptions
                      .filter(
                        (menuOption: any) => menuOption.title !== "Edit Roles"
                      )
                      .map((menu: any) => (
                        <Menu.Item>
                          <div
                            onClick={() => handleClick(menu.actionFunction)}
                            className={`block px-4 py-2 text-sm text-gray-700 capitalize "cursor-pointer" hover:bg-gray-100`}
                          >
                            {menu.title}
                          </div>
                        </Menu.Item>
                      ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </td>
          ) : (
            <td></td>
          )
        ) : (
          // pathname === "/super-user/resellers"
          //   ? <td></td>
          //   :
          <td
            className={`${openAccordion ? "text-gray-800" : "text-gray-800"}`}
          >
            <span className="ml-6 flex h-7 items-center">
              <ChevronUpIcon
                className={`h-6 w-6 transition-transform duration-200 ${
                  openAccordion ? "" : "rotate-180"
                } `}
                aria-hidden="true"
              />
            </span>
          </td>
        )}
        {edit && (
          <EditUserModal
            user={user}
            modalProps={{ open: edit, setOpen: setedit }}
            fetchUsers={fetchUsers}
            getUserAssignedCompanyAndRoles={getUserAssignedCompanyAndRoles}
          />
        )}
        {assignCustomer && (
          <AssignCustomerModal
            user={user}
            allCustomers={allCustomers}
            modalProps={{ open: assignCustomer, setOpen: setAssignCustomer }}
            fetchUsers={fetchUsers}
            getCustomersAssignedToUsers={getCustomersAssignedToUsers}
          />
        )}
      </tr>
      {openAccordion && (
        <tr className={`cursor-pointer ${selectedUser === user.id ? "" : ""}`}>
          <td
            className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900"
            colSpan={6}
          >
            <UserAccordion
              user={user}
              setOpenAccordion={setOpenAccordion}
              companies={companies}
              userAssignedCompanies={userAssignedCompanies}
              menuOptions={menuOptions}
              getUserAssignedCompanyAndRoles={getUserAssignedCompanyAndRoles}
              editRoles={editRoles}
              setEditRoles={setEditRoles}
              handleClick={handleClick}
              fetchUsers={fetchUsers}
              setAssignCustomer={setAssignCustomer}
              totalCustomersAssigned={totalCustomersAssigned}
            />
          </td>
        </tr>
      )}
    </>
  );
}

export default UsersTableRow;
