import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";
import LoadingButton from "../../components/LoadingButton";
import Loader from "../../components/Loader";
import { get, post } from "../../utils/httpMethods";
import { GlobalToasterContext } from "../../contexts/ToasterContext";
import { CheckIcon } from "@heroicons/react/24/outline";
import useAuth from "../../hooks/useAuth";
import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";

interface props {
  modalProps: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
}

const authAppMethods = [
  {
    label: "Google Authenticator",
    value: "Google_Authenticator",
  },
  {
    label: "Duo (Coming soon)",
    value: "Duo",
    isDisabled: true,
  },
];

function MfaSettings({ modalProps }: props) {
  const { open, setOpen } = modalProps;
  const cancelButtonRef = useRef(null);
  const [authApp, setAuthApp] = useState("Google_Authenticator");
  const [otp, setOTP] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [backupCodes, setBackupCodes] = useState([]);
  const { setToasterProps } = useContext(GlobalToasterContext)!;
  const { logout, fetchUser } = useAuth();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("currentUser") as any)
  );
  const navigate = useNavigate();

  const [qrData, setQrData] = useState({
    url: "",
    secret: "",
  });
  const handleClose = () => {
    setIsSuccess(false);
    setMessage("");
    setQrData({
      url: "",
      secret: "",
    });
  };
  const handlesubmit = async () => {
    setIsConfirming(true);
    setMessage("");

    try {
      const result: any = await post(`api/users/MFA/setup`, {
        mfa_app: authApp,
        otp: otp,
        secret_code: qrData.secret,
      });
      setIsConfirming(false);
      setIsSuccess(true);
      setMessage(result.message);
      setBackupCodes(result.backup_codes);
      fetchUser();
    } catch (e: any) {
      setIsConfirming(false);
      setMessage(e.message);
      setIsSuccess(false);
    }
  };

  const DownloadButton = ({ textOutput }: { textOutput: string }) => {
    const file = new Blob([textOutput], { type: "text/plain" });
    return (
      <a
        download="WanAware.BackupCodes.txt"
        target="_blank"
        rel="noreferrer"
        href={URL.createObjectURL(file)}
        style={{
          textDecoration: "inherit",
          color: "inherit",
        }}
      >
        <span className="text-primary">
          <ArrowDownTrayIcon className="h-4 inline" /> Download codes
        </span>
      </a>
    );
  };

  const fetchQRAndCode = async () => {
    setIsLoading(true);
    try {
      const data: any = await get(`api/users/MFA/setup`);
      setQrData({ url: data.qr_url, secret: data.secret_code });
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
      setToasterProps({
        show: true,
        message: e.message || e.error,
        severity: "error",
      });
    }
  };
  useEffect(() => {
    open && fetchQRAndCode();
  }, [open]);
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={handleClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto ">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                {!isSuccess ? (
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                    <div>
                      <Dialog.Title
                        as="h2"
                        className="text-lg font-bold leading-6 text-gray-900 mb-3"
                      >
                        Multi-Factor Authentication
                      </Dialog.Title>
                      <div className="flex flex-col gap-4 text-sm leading-6 text-gray-600 mt-5">
                        <span className="">
                          Multi-factor authentication (MFA) has been enabled on
                          your account. With MFA enabled, you will be asked to
                          provide a one-time password (OTP) in addition to your
                          username and password.
                        </span>
                        <span>
                          You will need an authenticator app installed on your
                          device to proceed.{" "}
                        </span>
                      </div>
                      <label className="block text-sm font-medium leading-6 text-gray-900 mt-3">
                        Select Authenticator
                      </label>
                      <div className="grid grid-cols-3 mt-2">
                        <Select
                          className={`ring-primary-500 outline-none self-end col-span-2`}
                          onChange={(e: any) => setAuthApp(e.value)}
                          placeholder="Select MFA APP"
                          options={authAppMethods}
                          defaultValue={authAppMethods[0]}
                        />
                      </div>

                      <div className="leading-6 text-gray-600 mt-5">
                        1. Scan the barcode with your authenticator app :
                      </div>
                      <div className="grid grid-cols-2 m-4">
                        <div className="w-60 h-60 flex  justify-center">
                          {!isLoading ? (
                            <img src={qrData.url} alt="" />
                          ) : (
                            <Loader className="h-10 w-10 border-4 self-center" />
                          )}
                        </div>
                        <div className="flex flex-col justify-items-start self-center">
                          <span className="text-sm leading-6 text-gray-600">
                            Or enter this code into the Authentication App:
                          </span>
                          <span className="mt-5">{qrData.secret}</span>
                        </div>
                      </div>

                      <div className="leading-6 text-gray-600 mt-3">
                        2. Enter the OTP generated by your authenticator app:
                      </div>
                      <div className="grid grid-cols-3 mt-1">
                        <input
                          type={"text"}
                          onChange={(e) => setOTP(e.target.value)}
                          placeholder="Enter one time password"
                          className={`block w-full col-span-2 rounded-md  p-2 border-0 py-1.5 text-gray-900 outline-none shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-md focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:leading-6`}
                        />
                      </div>
                      {message ? (
                        <span className="text-sm leading-6 text-red-600">
                          {message}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="flex mt-9 space-x-2 justify-end">
                      <button
                        type="button"
                        onClick={() => logout()}
                        className="rounded-md bg-primary-50 px-2.5 py-1.5 text-sm font-semibold text-primary-600 shadow-sm hover:bg-primary-100"
                      >
                        Logout
                      </button>

                      <LoadingButton
                        type="submit"
                        className="rounded-md bg-primary-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 disabled:bg-primary-100"
                        buttonText={"Confirm"}
                        loading={isConfirming}
                        onClick={handlesubmit}
                      />
                    </div>
                  </Dialog.Panel>
                ) : (
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div>
                      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <CheckIcon
                          className="h-6 w-6 text-green-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          You're all set!
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">{message}</p>
                        </div>
                        <div className="mt-2">
                          <div className="grid grid-cols-2 gap-x-4 gap-y-5 py-6">
                            {backupCodes.map((code) => (
                              <div className="font-medium">{code}</div>
                            ))}
                          </div>
                        </div>
                        <div className="mt-2">
                          <DownloadButton textOutput={backupCodes.join(", ")} />
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                        onClick={() => logout()}
                      >
                        Logout now
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={() => {
                          handleClose();
                          setOpen(false);
                          navigate(
                            [
                              "WanAware_Finance",
                              "Reseller_Finance",
                              "Customer_Finance",
                            ].includes(user.role)
                              ? "/billing"
                              : [
                                  "WanAware_Super_User",
                                  "Reseller_Admin",
                                ].includes(user.role)
                              ? "/super-user/customers"
                              : "/dashboard",
                            { replace: true }
                          );
                        }}
                        ref={cancelButtonRef}
                      >
                        Stay logged in
                      </button>
                    </div>
                  </Dialog.Panel>
                )}
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default MfaSettings;
