import React from "react";
import Loader from "./Loader";

function LoadingScreen() {
  return (
    // <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
    <div className="text-center">
      <p className="mt-6 text-base leading-7 text-gray-600 text-center">
        <div>
          <Loader className="h-14 w-14 text-center m-auto" />
        </div>
        Please wait...
      </p>
    </div>
    // </main>
  );
}

export default LoadingScreen;
